import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Aboutus = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
   <>
    <div className="header2 about-bg">
            <section className="container techdesk-container pt-5">
              <div className="row pt-5">
                <div 
                data-aos="fade-right"
                className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">
                {/* <p className="subtitle">
                ABOUT US
                  </p> */}
                  <h2 className="techdesk-title pb-5">
                  Empowering Electric Mobility
                  </h2>
                 
                  <p className="techdeskabout-para">
                  {/* EVET - an acronym for Electric Vehicle Enabled Transport and the mobility arm of Magenta, enables farms, industries and businesses to take a comprehensive look and remove carbon across their transportation requirements to electric vehicles. */}
                  Magenta's mobility arm is focused on helping farms, industries, and businesses transition to electric vehicles in order to reduce their carbon emissions. This is an important step towards sustainability and reducing the impact of transportation on the environment. 
                  </p>
                  <p className="techdeskabout-para">
                  Backed with data and AI-led models, we are assisting businesses to accelerate their journey towards sustainability.
                  </p>
                  <p className="techdeskabout-para">
                  Our parallel business units - ChargeGrid and Infomatics, enables us to bundle our mobility services with reliable charging hubs supported by technology and analytics to accelerate delivery speeds with smarter logistics solution.

                  </p>


                </div>
                {/*  --------------- main header right side--------------  */}
                <div
                data-aos="fade-left"
                className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                  <img
                    src="./images/abouthero.png"
                    alt="heroimg"
                    className="img-fluid"
                  />
                  {/* <img
                    src="./images/hero4.jpg"
                    alt="heroimg4"
                    className="img-fluid main-hero-img2"
                  /> */}
                </div>
              </div>
            </section>
          </div>
   </>
  )
}

export default Aboutus