import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


const Mobility = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className="header1">
        <section className="container techdesk-container probootstrap-section">
          <div className="row flex-column-reverse flex-md-row">
          <div
            data-aos="fade-right"
             className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
              <img
                src="./images/mobile2.png"
                alt="heroimg"
                className="img-fluid"
              />
              {/* <img
                    src="./images/hero4.jpg"
                    alt="heroimg4"
                    className="img-fluid main-hero-img2"
                  /> */}
            </div>
            <div
            data-aos="fade-left"
             className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">
              {/* <p className="subtitle">
                Our services includes
                  </p> */}
              <h2 className="techdesk-title">
                Electric Vehicles + Charging Infrastructure
              </h2>
              <div className="linePreloader"></div>



              <p className="techdeskabout-para pt-5">
              Under our brand "ChargeGrid" :
                  </p>
                  <ul className="list-group list-para">
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i> Magenta scouts, installs and operates EV charging stations for EVs.
                
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i> Charging hubs are strategically positioned near the vendor warehouses, hubs and distribution centers.  
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i> Completely automated, reliable, regulated and safe charging stations. 
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Increases levels of accuracy.  
                </li>
              </ul>

              <p className="techdeskabout-para pt-3">
              This allows Magenta Mobility to provide end to end solutions to its customers with Electric Vehicles, Charging Infrastructure, Software Solutions and Daily operations.
                  </p>
              {/* <form class="d-flex">
                   <button class="btn btn-style" type="submit">Read More</button>
                   </form> */}

            </div>
            {/*  --------------- main header right side--------------  */}

          </div>
        </section>
      </div>
    </>
  );
};


export default Mobility