import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css'

const Navbar = () => {
  const [show, setShow] = useState(true);

  return (
    <>
      <header className="header">
        <div className="header-inner">
          <div className="container px-lg-5">
            <nav className="navbar navbar-expand-lg my-navbar ">

              <NavLink className="navbar-brand " onClick={() =>window.open("https://www.magentamobility.com/","_self")}to="/"><img src="images/Magentalogo.svg" alt="logo" /></NavLink>
              {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>setShow(!show)}> */}
              <button className="navbar-toggler" type="button" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShow(!show)}>
                <span className="navbar-toggler-icon" ><i className="fas fa-bars fa-xl" ></i></span>
              </button>

              <div className={`collapse navbar-collapse ${show ? "show" : " "}`} >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <NavLink className="nav-link " onClick={() =>window.open("https://www.magentaevet.com","_self")} to="/">Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" onClick={() => setShow(!show)} to="/about">About Mobility</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" onClick={() =>window.open("https://www.magentamobility.com/contact","_self")} to="/"> Contact</NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink className="nav-link" onClick={() => setShow(!show)} to="/evetalprogram">AL Program</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link " onClick={() => setShow(!show)} to="/login">Log In</NavLink>
                  </li> */}
                </ul>

              </div>
            </nav>

          </div>
        </div>

      </header>

    </>
  )
}

export default Navbar