import React,{useEffect} from "react";
// import fleet from "../../../public/images/fleet.gif"

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <div className="header2">
                <section className="container techdesk-container probootstrap-section">
                    <div className="row ">
                        <div className="col-12 col-lg-12 p-5 header-left-side d-flex justify-content-center flex-column  align-items-center">
                            <p className="subtitle">
                                TERMS OF USAGE                </p>
                                
                            <h2 className="techdesk-title">
                                It’s about our clients, associates and partners

                            </h2>
                            <div className="linePreloader1"></div>
                            <div class="col-md-12 about_story">
                                <p className="techdesk-para">Welcome to <a href="www.magentaevet.com" >www.magentaevet.com. </a>The website <a href="www.magentaevet.com" >www.magentaevet.com</a> is owned and operated by Magenta EV Solutions Private Limited. For the purposes of these terms of use (Terms of Use) and the
                                    website, the laws of the Republic of India shall be applicable law. Your usage of this website
                                    is subject to your express consent that the laws of the Republic of India shall be applicable
                                    law. Magenta and the website are together referred as the Platform. You may be accessing our
                                    website from a computer or mobile phone device (running either an iOS or Android operating
                                    system, for example) and these Terms of Use govern your use of our website and your conduct,
                                    regardless of the means of access. These Terms of Use govern all the products offered on the
                                    website. These Terms of Use and such other policies as may be amended from time to time that
                                    govern this website shall together constitute the entire terms on which you may use this website
                                    and shall be deemed to have been set out herein as if expressly set out herein.</p>
                                <p className="techdesk-para"><b>ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR UNCONDITIONAL AGREEMENT TO
                                    ALL THE TERMS AND CONDITIONS IN THIS AGREEMENT, SO PLEASE READ THIS AGREEMENT CAREFULLY
                                    BEFORE PROCEEDING.</b></p>
                                <p className="techdesk-para">If you do not agree to any of the terms enumerated in the Terms of Use or the policies, please do
                                    not use the website. You are responsible to ensure that your access to this website and material
                                    available on or through it are legal in each jurisdiction, in or through which you access or
                                    view the website or such material.</p>
                                <p className="techdesk-para">Magenta reserves the unilateral, sole and exclusive right to change the particulars contained in
                                    the Terms of Use and the policies from time to time and at any time, without notice to its users
                                    and in its sole, exclusive and absolute discretion. If Magenta decides to change the Terms of
                                    Use or the policies, Magenta will post the new version of the Terms of Use or the policies on
                                    the website and update the date specified above. Any change or modification to the Terms of Use
                                    and the policies will be effective immediately from the date of such upload on the website. Your
                                    continued use of the website following the modifications to the Terms of Use and the policies
                                    constitutes your acceptance of the modified Terms of Use and the policies whether or not you
                                    have read them. For this reason, you should frequently review these Terms of Use and any other
                                    applicable policies, including their dates, to understand the terms and conditions that apply to
                                    your use of the website. Under no circumstances shall Magenta be obligated to inform you of any
                                    change in its Terms of Use and/ or policies. </p>
                                <h3>Limited License</h3>
                                <p className="techdesk-para">Magenta grants you limited, non-exclusive, non-transferable, non-sublicensable license to access,
                                    and make personal and non-commercial use of the website. All rights not expressly granted to you
                                    in these Terms of Use, are reserved and retained by the website and its affiliates.</p>
                                <p className="techdesk-para">Magenta reserves the right, at any time, without notice, and at its sole discretion, to terminate
                                    your license to use the website and to block and prevent your future access the website.</p>
                                <h3>Intellectual Property</h3>
                                <p className="techdesk-para">This web site is the exclusive property of Magenta EV Solutions Private Limited Any material that it
                                    contains, including, but not limited to, texts, data, graphics, pictures, sounds, videos, logos,
                                    icons or html code is protected under the intellectual property laws and remains Magenta or
                                    third party's property.</p>
                                <p className="techdesk-para">You may use this material for personal and non-commercial purposes in accordance with the
                                    principles governing intellectual property laws. Any other use or modification of the content of
                                    Magenta's web site without Magenta's prior written authorisation is prohibited. Framing this
                                    site or using our proprietary marks as meta tags by you are any person acting on your behalf,
                                    without our written consent is impermissible. Use of contact information provided on the site
                                    for unauthorized purposes, including marketing is prohibited. Any use intended to impede with
                                    the working of the site or to intercept any information from the site is prohibited.</p>
                                <h3>Links to Third-Party Web Sites</h3>
                                <p className="techdesk-para">Links to third-party web sites are provided solely as a convenience and do not imply any approval
                                    or endorsement by Magenta of the linked sites, even if they may contain Magenta's logo, as such
                                    sites are beyond the Magenta's control. Thus, Magenta cannot be held responsible for the content
                                    of any linked site or any link contained therein.</p>
                                <p className="techdesk-para">Magenta is not responsible for the content of any third-party sites and does not make any
                                    representations regarding the content or accuracy of material on such sites. If you decide to
                                    link to any such third-party websites, you do so entirely at your own risk. Magenta does not
                                    assume any responsibility for examining or evaluating the offerings of the off-websites pages or
                                    any other websites linked from the website. We shall not be responsible for the actions,
                                    content, products, or services of such pages and websites, including, without limitation, their
                                    privacy policies and terms and conditions. You should carefully review the terms and conditions
                                    and privacy policies of all off-website pages and other websites that you visit via the website.
                                </p>
                                <h3>User Content</h3>
                                <p className="techdesk-para">The information, name, phone number, card details, photo, image, chat communication, text,
                                    software, data, music, sound, graphics, messages, videos or other materials transmitted,
                                    uploaded, posted, e-mailed or otherwise made available to us ("User Content"), are entirely your
                                    responsibility and we will not be held responsible, in any manner whatsoever, in connection to
                                    the User Content. You agree to not encourage or assist or engage others as well as yourself in
                                    transmitting, hosting, displaying, uploading, modifying, publishing transmitting, updating or
                                    sharing any information that</p>
                                <ul class="list-group list-para">
                                    <li class="list-group-item d-flex">
                                        <i class="bi bi-patch-check-fill text-success me-2"></i>belongs to another person and to which the user does not have any right to.</li>
                                    <li class="list-group-item d-flex"><i class="bi bi-patch-check-fill text-success me-2"></i>is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, paedophilic,
                                        libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable,
                                        disparaging, relating or encouraging money laundering or gambling, trolling, propaganda or
                                        otherwise unlawful in any manner whatever.</li>
                                    <li class="list-group-item d-flex"><i class="bi bi-patch-check-fill text-success me-2"></i>harm minors or threatens to harm minors in any way.</li>
                                    <li class="list-group-item d-flex"><i class="bi bi-patch-check-fill text-success me-2"></i>infringes any patent, trademark, copyright or other proprietary rights.</li>
                                    <li class="list-group-item d-flex"><i class="bi bi-patch-check-fill text-success me-2"></i>violates any law for the time being in force.</li>
                                    <li class="list-group-item d-flex"><i class="bi bi-patch-check-fill text-success me-2"></i>deceives or misleads the addressee about the origin of such messages or communicates any
                                        information which is grossly offensive or menacing in nature.</li>
                                    <li class="list-group-item d-flex"><i class="bi bi-patch-check-fill text-success me-2"></i>impersonate another person.</li>
                                    <li class="list-group-item d-flex"><i class="bi bi-patch-check-fill text-success me-2"></i>contains software viruses or any other computer code, files or programs designed to
                                        interrupt, destroy or limit the functionality of any computer resource.</li>
                                    <li class="list-group-item d-flex"><i class="bi bi-patch-check-fill text-success me-2"></i>threatens the unity, integrity, defence, security or sovereignty of India, friendly
                                        relations with foreign states, or public order or causes incitement to the commission of any
                                        cognizable offence or prevents investigation of any offence or is insulting any other
                                        nation.
                                    </li>
                                </ul>
                                <p className="techdesk-para">Magenta shall in no way be held responsible for examining or evaluating User Content, nor does it
                                    assume any responsibility or liability for the User Content. Magenta does not endorse or control
                                    the User Content transmitted or posted on the website by you and therefore, accuracy, integrity
                                    or quality of User Content is not guaranteed by Magenta.. Under no circumstances will Magenta be
                                    liable in any way for any User Content, including without limitation, for any errors or
                                    omissions in any User Content, or for any loss or damage of any kind incurred by you as a result
                                    of the use of any User Content transmitted, uploaded, posted, e-mailed or otherwise made
                                    available via the website. You hereby waive all rights to any claims against Magenta and/ or
                                    their associates for any alleged or actual infringements of any proprietary rights, rights of
                                    privacy and publicity, moral rights, and rights of attribution in connection with User Content.
                                </p>
                                <p className="techdesk-para">You hereby acknowledge that Magenta has the right (but not the obligation) in its sole discretion
                                    to refuse to post or remove any User Content and further reserves the right to change, condense,
                                    or delete any User Content. Without limiting the generality of the foregoing or any other
                                    provision of these Terms of Use, Magenta has the right to remove any User Content that violates
                                    these Terms of Use or is otherwise objectionable and further reserves the right to refuse
                                    service and/or terminate accounts without prior notice for any users who violate these Terms of
                                    Use or infringe the rights of others.</p>
                                <p className="techdesk-para">If you wish to delete your User Content on our website or in connection with our mobile
                                    applications, please contact us by e-mail at <a href="mailto: sales@magentagroup.com" >sales@magentagroup.com</a> and request you to include
                                    the following personal information in your deletion request: first name, last name, user
                                    name/screen name (if applicable), e-mail address associated with our website and/or mobile
                                    applications, your reason for deleting the posting, and date(s) of posting(s) you wish to delete
                                    (if you have it). We may not be able to process your deletion request if you are unable to
                                    provide such information to us. Please allow up to 30 (thirty) business days (which it is
                                    clarified shall not include weekends or public holidays) to process your deletion request.</p>
                                <h3>Warranty and Liability</h3>
                                <p className="techdesk-para">All materials, including downloadable software, contained in Magenta's web site is provided on
                                    'as is' basis and without warranty of any kind to the extent allowed by the applicable law.
                                    While Magenta will use reasonable efforts to provide reliable information through its web site,
                                    Magenta does not warrant that this web site is free of inaccuracies, errors and/or omissions,
                                    viruses, worms, Trojan and the like, or that its content is appropriate for your particular use
                                    or up to date. Magenta reserves the right to change the information at any time without notice.
                                    Magenta does not warrant any results derived from the use of any software available on this
                                    site. You are solely responsible for any use of the materials contained in this site.</p>
                                <p className="techdesk-para">The information contained in this site does not extend or modify the warranty that may apply to
                                    you as a result of a contractual relationship with Magenta.</p>
                                <p className="techdesk-para">Magenta will not be liable for any indirect, consequential or incidental damages, including but
                                    not limited to loss of profits or revenues, business interruption, loss of data arising out of
                                    or in connection with the use, inability to use or reliance on any material contained in this
                                    site or any linked site.</p>
                                <h3>Users' Comments and Information Collected</h3>
                                <p className="techdesk-para">Magenta does not assume any obligation to monitor the information that you may post on its
                                    website.</p>
                                <p className="techdesk-para">You warrant that any and all information projects, files or other attachments sent to us
                                    ('Material') or comments other than personal data, that you may transmit to Magenta through the
                                    website does not infringe intellectual property rights or any other applicable law. Such
                                    information, material or comments, will be treated as non-confidential and non-proprietary. By
                                    submitting any information or material, you give Magenta an unlimited and irrevocable license to
                                    use, execute, show, modify and transmit such information, material or comments, including any
                                    underlying idea, concept or know-how. Magenta reserves the right to use such information in any
                                    way it chooses.</p>
                                <p className="techdesk-para">We collect information from a variety of sources, directly and indirectly [to specify the
                                    sources]. The term "personal data" means information relating to an identified or identifiable
                                    individual (i.e. a natural person). All of the information we collect about you is used to
                                    monitor the use of the site and develop world-class products and services of superior quality.
                                    Such use does not result in any personally identifiable information being collected or stored.
                                    By submitting your Personal Data through this website, you shall be consenting to it being
                                    handled in accordance with the site's online Privacy Policy. You agree to provide true and
                                    complete information about yourself by registering on this site and you are solely responsible
                                    for protecting your password and all acts by way of use of your password. If you have submitted
                                    Personal Data through this website and wish us to cease using it for the purposes submitted,
                                    please contact us.</p>
                                <h3>Applicable law - Severability</h3>
                                <p className="techdesk-para">These Terms of Use are governed by the Indian Law. The courts in Mumbai have exclusive
                                    jurisdiction in relation to any claim or action arising out of, or in connection with, the Terms
                                    of Use.</p>
                                <p className="techdesk-para">If any provision of these Terms of Use is held by a court to be illegal, invalid or
                                    unenforceable, the remaining provisions shall remain in full force and effect.</p>
                                <h3>Modifications of the Terms of Use</h3>
                                <p className="techdesk-para">Magenta reserves the right to change the Terms of Use under which this web site is offered at any
                                    time and without notice. You will be automatically bound by these modifications when you use
                                    this site, and should periodically read the Terms of Use.</p>
                                <p className="techdesk-para">After having read and understood the disclaimer and copyright notice, I hereby agree to accept
                                    and abide by all the terms and conditions as mentioned therein at my sole responsibility. I
                                    agree.</p>
                                <h3>Copyright Notice</h3>
                                <p className="techdesk-para">All rights reserved. Copyright in all material on this website vests in Magenta EV Solutions Private Limited and is protected under copyright.</p>
                                <h3>Copyright</h3>
                                <p className="techdesk-para">All content included on this site, such as text, graphics, logos, button icons, images, audio
                                    clips, digital downloads, data compilations, and software, is the sole property of Magenta EV Solutions Private Limited and protected copyright.</p>
                                <h3>Trademarks</h3>
                                <p className="techdesk-para">The marks 'Magenta’,' ChargeGrid’ and ‘Magenta mobility’ as well as the Magenta mobility Logo indicated
                                    on each page are the trademarks of Magenta EV Solutions Private Limited. All graphics, logos, page
                                    headers, button icons, scripts and service names (collectively 'Trademarks') present and visible
                                    on this site are trademarks of Magenta EV Solutions Private Limited. The Trademarks 'Magenta’,'
                                    ChargeGrid’ and ‘Magenta mobility as well as the Logo may not be used in connection with any
                                    product or service that is not in any manner connected or provided by Magenta EV Solutions Private Limited, or
                                    in any manner that is likely to cause confusion among customers, or in any manner that
                                    disparages or discredits Magenta EV Solutions Private Limited. All other trademarks not owned by
                                    Magenta EV Solutions Private Limited or its subsidiaries that appear on this site, are the property of
                                    their respective owners, who may or may not be affiliated with or connected to Magenta EV Solutions Private Limited.</p>


                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    );
};

export default Terms;
