import React from "react";
import { NavLink } from 'react-router-dom';


const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 mx-auto">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 pt-5">
                  <h2>Company</h2>
                  <p className="footer-para">
                  Magenta Mobility is a brand under Magenta EV Solutions Pvt. Ltd. Magenta has been pioneering end-to-end electric vehicle solutions since 2018 with solutions including designing, developing and manufacturing EV chargers, setting up and operating EV charging stations and providing electric fleet for transportation and logistics.
                  </p>
                </div>

                <div className="col-6 col-md-2 col-lg-2 pt-5">
                  <h2>Quick Links</h2>
                  <ul>
                    <li>
                    <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                    <NavLink to="/terms">Terms</NavLink>
                    </li>
                    <li>
                    <NavLink to="/policy">Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to="/disclaimer">Disclaimer</NavLink>
                    </li>
                  </ul>
                </div>

                <div className="col-6 col-md-2 col-lg-2 pt-5">
                  <h2>Services</h2>
                  <ul>
                    <li>
                      <a >Electric First-Mile</a>
                    </li>
                    <li>
                      <a>Zero Carbon Mid-Mile</a>
                    </li>
                    <li>
                      <a>Clean Last-Mile</a>
                    </li>
                    <li>
                      <a>Reverse Logistics</a>
                    </li>
                  </ul>
                </div>

                <div className="col-12 col-md-3 col-lg-3 pt-5">
                  <h2>Follow Us</h2>
                  <div className="row">
                    <div className="col-2 mx-auto">
                    <a
                        href="https://www.facebook.com/MagentaMobility"
                        target="_blank">
                      <i class="fab fa-facebook-f fontawesome-style"></i>
                      </a>
                    </div>
                    <div className="col-2 mx-auto">
                      <a
                        href="https://www.instagram.com/magentamobility/"
                        target="_blank">
                        <i class="fab fa-instagram fontawesome-style"></i>
                      </a>
                    </div>
                    <div className="col-2 mx-auto">
                      <a
                        href="https://www.linkedin.com/company/magentamobility/"
                        target="_blank">
                        <i class="fab fa-linkedin fontawesome-style"></i>
                      </a>
                    </div>
                    <div className="col-2 mx-auto">
                    <a
                        href="https://www.youtube.com/channel/UC0LXROPJWmZ6zTZzBArGGeQ"
                        target="_blank">
                      <i class="fab fa-youtube fontawesome-style"></i>
                      </a>
                    </div>
                    <div className="col-2 mx-auto">
                    <a
                        href="https://twitter.com/MagentaMobility"
                        target="_blank">
                      <i class="fab fa-twitter fontawesome-style"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="mt-5">
                <p className="main-hero-para text-center w-100">
                  Copyright @ 2022 Magenta Mobility. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
