import React, { useEffect,useState } from "react";import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactHero = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    message: "",
});

let name, value;
const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setUserData({ ...userData, [name]: value });
};

// connect with firebase
const submitData = async (event) => {
    event.preventDefault();
    const { firstName, lastName, phone, email, address, message } = userData;


    if (!firstName || !lastName || !phone || !email || !address || !message){
        toast.error("Please enter details");

    }
    else{
        const res = fetch(
            "https://evetweb-b689c-default-rtdb.firebaseio.com/userDataRecords.json",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    phone,
                    email,
                    address,
                    message,
                }),
            }
        );

        if (res) {
            setUserData({
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                address: "",
                message: "",
            });
            toast.success("Form submitted successfully");  
        } else {
            toast.error("Please enter details");
        }
        }

    // if (firstName && lastName && phone && email && address && message) {
    //     const res = fetch(
    //         "https://evetwebsite-default-rtdb.firebaseio.com/userDataRecords.json",
    //         {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 firstName,
    //                 lastName,
    //                 phone,
    //                 email,
    //                 address,
    //                 message,
    //             }),
    //         }
    //     );

    //     if (res) {
    //         setUserData({
    //             firstName: "",
    //             lastName: "",
    //             phone: "",
    //             email: "",
    //             address: "",
    //             message: "",
    //         });
    //         alert("Form Submitted");
    //     } else {
    //         alert("plz fill the data");
    //     }
    // } else {
    //     alert("plz fill the data");
    // }
};

  return (
   <>
    <div className="header2 about-bg">
   
            <section className="contactus-section techdesk-container probootstrap-section ">
                <div className="container ">
                <ToastContainer position="top-center"/>
                    <div className="row pt-5">
                        <div
                        data-aos="fade-left"
                        className="contact-rightside col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">

                            <form method="POST">
                                <div className="row">
                                    <div className="contact-container ">
                                        <h2 className="techdesk-title">
                                        Your goods, our wheels </h2>
                                        <p className="techdesk-para">
                  We are just one click away to help you make your transportation clean and efficient.  Fill in the form to share more details about your requirement, we’d love to talk.
                  </p>
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild ">
                                        <input
                                            type="text"
                                            name="firstName"
                                            id=""
                                            className="form-control"
                                            placeholder="First Name"
                                            value={userData.firstName}
                                            onChange={postUserData}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="text"
                                            name="lastName"
                                            id=""
                                            className="form-control"
                                            placeholder="Last Name"
                                            value={userData.lastName}
                                            onChange={postUserData}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="text"
                                            name="phone"
                                            id=""
                                            className="form-control"
                                            placeholder="Phone Number "
                                            value={userData.phone}
                                            onChange={postUserData}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="text"
                                            name="email"
                                            id=""
                                            className="form-control"
                                            placeholder="Email ID"
                                            value={userData.email}
                                            onChange={postUserData}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 contact-input-feild">
                                        <input
                                            type="text"
                                            name="address"
                                            id=""
                                            className="form-control"
                                            placeholder="Add Address"
                                            value={userData.address}
                                            onChange={postUserData}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 ">
                                        <input
                                            type="text"
                                            name="message"
                                            id=""
                                            className="form-control"
                                            placeholder="Enter Your Message"
                                            value={userData.message}
                                            onChange={postUserData}
                                        />
                                    </div>
                                </div>
                                <div class="form-check py-3">
                                </div>

                                <button
                                    type="submit"
                                    className="btn btn-style w-100"
                                    onClick={submitData}>
                                    Submit
                                </button>
                            </form>
                        </div>
                        <div
                        data-aos="fade-right"
                         className="col-12 col-lg-6 pb-5  p-5 p-5 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                            <img
                            src="./images/contact.png"
                            alt="heroimg"
                            className="img-fluid"
                            />

                        </div>
                    </div>
                </div>

            </section>

          </div>
   </>
  )
}

export default ContactHero