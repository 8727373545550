import React, { useEffect } from "react";
import Aboutus from './aboutus';
import History from './history';
import Mission from './mission';
import Team from './team';
import Team2 from './team2';
import './about.css'
import Helmet from 'react-helmet';
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (

    <>
      <Helmet>
      <title>Magenta Mobility</title>
      <meta name="description" content="Share Your Requirements With Us" />  
      <meta name="keywords" content="EVET - an acronym for Electric Vehicle Enabled Transport and the mobility arm of Magenta, enables farms, industries and businesses to take a comprehensive look and remove carbon across their transportation requirements to electric vehicles"/>
      </Helmet>

    <Aboutus/>
    <History />
    {/* <Mission /> */}
    {/* <Team /> */}
    <Team2 />
    </>
  )
}

export default About