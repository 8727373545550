import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


const Vehicles = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>
            <div className=''>
                <section className="container techdesk-container pb-5">
                    <div className="row flex-column-reverse flex-md-row">
                        <div
                            data-aos="fade-right"
                            className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                            <img
                                src="./images/vehicles.png"
                                alt="heroimg"
                                className="img-fluid"
                            />
                        </div>
                        <div data-aos="fade-left"
                            className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">
                            <h2 className="techdesk-title">
                                Our Capabilities - Vehicles
                            </h2>
                            <p className="techdeskabout-para pt-5">
                                Taking into consideration everything from practicality, safety, maintenance and client satisfaction, at EVET, we consider three things when selecting our vehicles:
                            </p>
                            <ul className="list-group list-para">
                                <li className="list-group-item d-flex">
                                     1. Safety and Accident ratings
                                </li>
                                <li className="list-group-item d-flex">
                                    2. Use and Operation (specific to customer requirements)
                                </li>
                                <li className="list-group-item d-flex">
                                    3. Maintenance and Upkeep
                                </li>

                            </ul>
                            <p className="techdeskabout-para pt-3">
                            Ultimately, every fleet operation is different and there is no one-size-fits-all solution.
                            </p>
                            <ul className="list-group list-para">
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i>  Highest Safety and NCAP rated vehicles
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i>  Latest technology enabled vehicles
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> Vehicles with higher travelling range (kms/charge)
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i>  Vehicles with higher loading capacity (kgs)
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i>  Fast charging options(0 to 80% in 20 mins)
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i>  CAN based telematics enabled
                                </li>
                            </ul>

                        </div>
                        {/*  --------------- main header right side--------------  */}

                    </div>
                </section>
            </div>
        </>
    );
};


export default Vehicles