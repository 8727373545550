import React, { useEffect,useState } from "react";import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";


const Investordetails = () => {
    let navigate = useNavigate();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    accountnumber:"",
    ifscno:"",
    file: "",
    pancard:""
});
const [file, setFlie] = useState();
const [pancard, setPancard] = useState();

let name, value;
const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setUserData({ ...userData, [name]: value });
};

// connect with firebase
const submitData = async (event) => {
    event.preventDefault();
    navigate("../agreement", { replace: true });
    const { firstName, lastName, phone, email,accountnumber,ifscno, file,pancard } = userData;

    if (!firstName || !lastName || !phone || !email){
        toast.error("Please enter details");

    }
    else{
        const res = fetch(
            "https://evetweb-b689c-default-rtdb.firebaseio.com/userDataRecords.json",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    phone,
                    email,
                    accountnumber,
                    ifscno,
                    file,
                    pancard,
                  
                }),
            }
        );

        if (res) {
            setUserData({
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
            });
            toast.success("Form submitted successfully");  
        } else {
            toast.error("Please enter details");
        }
        }

};

  return (
   <>
    <div className="header2 about-bg">
   
    <section className="contactus-section techdesk-container probootstrap-section ">
                <div className="container ">
                <ToastContainer position="top-center"/>
                    <div className="row pt-5">
                        <div className="col-2 col-lg-2 "></div>
                        <div
                        data-aos="fade-left"
                        className="contact-rightside col-12 col-lg-8 p-5 header-left-side d-flex justify-content-center flex-column">

                            <form onSubmit={submitData}>
                                <div className="row">
                                    <div className="contact-container ">
                                        <h2 className="techdesk-title pb-5">
                                        Fill the following details</h2>
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild ">
                                        <input
                                            type="text"
                                            name="firstName"
                                            id=""
                                            className="form-control"
                                            placeholder="First Name"
                                            value={userData.firstName}
                                            onChange={postUserData}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="text"
                                            name="lastName"
                                            id=""
                                            className="form-control"
                                            placeholder="Last Name"
                                            value={userData.lastName}
                                            onChange={postUserData}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="text"
                                            name="phone"
                                            id=""
                                            className="form-control"
                                            placeholder="Phone Number "
                                            value={userData.phone}
                                            onChange={postUserData}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="text"
                                            name="email"
                                            id=""
                                            className="form-control"
                                            placeholder="Email ID"
                                            value={userData.email}
                                            onChange={postUserData}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="text"
                                            name="accountnumber"
                                            id=""
                                            className="form-control"
                                            placeholder="Account Number "
                                            value={userData.accountnumber}
                                            onChange={postUserData}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="text"
                                            name="ifscno"
                                            id=""
                                            className="form-control"
                                            placeholder="IFSC Number"
                                            value={userData.ifscno}
                                            onChange={postUserData}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="file"
                                            name="file"
                                            id=""
                                            className="form-control"
                                            placeholder="Upload Aadhar card"
                                            value={file}
                                            onChange={(e)=>{setFlie(e.target.file[0])}}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 contact-input-feild">
                                        <input
                                            type="file"
                                            name="pancard"
                                            id=""
                                            className="form-control"
                                            placeholder="Upload Pancard"
                                            value={pancard}
                                            onChange={(e)=>{setPancard(e.target.file[0])}}
                                        />
                                    </div>
                                </div>
             

                                <button
                                    type="submit"
                                    className="btn btn-style w-100"
                                    // onClick={submitData}
                                    >
                                    Submit
                                </button>
                            </form>
                        </div>
                        <div className="col-2 col-lg-2 "></div>
                    </div>
                </div>

            </section>

          </div>
   </>
  )
}

export default Investordetails