import React, { useEffect } from "react";
import '../about/about.css';
import AOS from "aos";
import "aos/dist/aos.css";

const Process = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>
            <div className='header1' >
                <section className="container techdesk-container">
                    <div className="row">
                        <div className="col-12 col-lg-12 ">

                            <h2 className="techdesk-title pt-5">
                            Magenta Mobility's Asset Leasing Programme - Get Started
                            </h2>
                   
                        </div>
     
                    </div>
                </section>
                <div className="container">
                <img
                                    src="./images/steps3.svg"
                                    alt="heroimg"
                                    className="img-fluid"
                                />
                </div>
            </div>
        </>
    )
}

export default Process