import React,{useEffect} from "react";
// import fleet from "../../../public/images/fleet.gif"

const Policy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <div className="header2">
                <section className="container techdesk-container probootstrap-section">
                    <div className="row ">
                        <div className="col-12 col-lg-12 p-5 header-left-side d-flex justify-content-center flex-column  align-items-center">
                            <p className="subtitle">
                                PRIVACY POLICY               </p>
                            <h2 className="techdesk-title">
                                It’s about our clients, associates and partners

                            </h2>
                            <div className="linePreloader1"></div>
                            <div class="col-md-12 about_story">
                                <p className="techdesk-para"><b>Magenta EV Solutions Private Limited</b>, is in the business of clean energy solutions – generation, adoption
                                    and utilisation and committed to providing state-of-the-art solutions.</p>
                                <p className="techdesk-para">We collect information about you when you visit our site, use our services, or view our online
                                    advertisements. The information which we collect and store during normal use of the site is used to monitor
                                    use of the site and to help further development of our products and services. In general, you can visit
                                    Magenta’s website on the Internet without telling us who you are or providing data that personally
                                    identifies you. Such use does not result in any personally identifiable data being collected or stored.</p>
                                <p className="techdesk-para">By submitting your Personal Data through this website, you shall be consenting to it being processed in the
                                    manner described above by Magenta and its affiliates around the world.</p>
                                <p className="techdesk-para">If you have submitted Personal Data through this website and wish us to cease using it for the purposes
                                    submitted, please contact us.</p>
                                <h3>Contacting Us</h3>
                                <p className="techdesk-para">We have taken adequate measures to secure access to your personal data</p>
                                <p className="techdesk-para">Magenta regularly reviews its compliance with this privacy statement. Please feel free to direct any
                                    questions or concerns regarding this privacy statement or our treatment of Personal Data and information by
                                    completing our web contact form or by writing to us at the following addresses:</p>
                                <p className="techdesk-para"><span>Registered Office:</span>  Magenta House, Plot No. D 285, MIDC, TTC Industrial Area, Turbhe, Navi Mumbai - 400 705.</p>
                                <p className="techdesk-para"><span>Corporate Office:</span>  Magenta House, Plot No. D 285, MIDC, TTC Industrial Area, Turbhe, Navi Mumbai - 400 705.</p>
                                <h3>Website Security</h3>
                                <p className="techdesk-para">You are prohibited from violating or attempting to violate the security of the website, including, without
                                    limitation,</p>
                                <ul class="list-group list-para">
                                    <li class="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> Accessing data not intended for you or logging onto a server or an account which you are not authorized
                                        to access.</li>
                                    <li class="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> Attempting to probe, scan or test the vulnerability of a system or network or to breach security or
                                        authentication measures without proper authorization.</li>
                                    <li class="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> Attempting to interfere with service to any other user, host or network, including, without limitation,
                                        via means of submitting a virus to the Site, overloading, "flooding", "spamming", "mail bombing" or
                                        "crashing".</li>
                                    <li class="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> Sending unsolicited e-mail, including promotions and/or advertising of products or services.</li>
                                    <li class="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> Forging any header or any part of the header information in any e-mail or newsgroup posting. Violations
                                        of system or network security may result in civil or criminal liability.
                                    </li>
                                </ul>
                                <p className="techdesk-para">Magenta EV Solutions Private Limited is entitled to investigate occurrences that may involve such violations and
                                    may involve, and co-operate with, law enforcement authorities in prosecuting users who are involved in such
                                    violations. You agree not to use any device, software or routine to interfere or attempt to interfere with
                                    the proper working of this website or any activity being conducted on this website. You agree, further, not
                                    to use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to
                                    navigate or search this website other than the search engine and search agents available from Magenta EV Solutions Private Limited on this website and other than generally available third-party web browsers (e.g., Netscape
                                    Navigator, Microsoft Explorer) nor to indulge in any reverse engineering of the website and/ or any
                                    application created by Magenta EV Solutions Private Limited.
                                </p>

                                <h3>Entire Agreement</h3>
                                <p className="techdesk-para">If any part of these Terms of Use is determined to be invalid or unenforceable pursuant to applicable law
                                    including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the
                                    invalid or unenforceable provision will be deemed to be superseded by a valid, enforceable provision that
                                    most closely matches the intent of the original provision and the remainder of the Terms of Use shall
                                    continue to be in effect. Unless otherwise specified herein, these Terms of Use constitutes the entire
                                    agreement between you and Magenta with respect to the website/ Platform and it supersedes all prior or
                                    contemporaneous communications and proposals, whether electronic, oral or written.</p>
                                <p className="techdesk-para">Magenta’s failure to act with respect to a breach by you or others does not waive its right to act with
                                    respect to the breach or subsequent and similar breaches.</p>
                                <h3>General</h3>
                                <p className="techdesk-para">You acknowledge and hereby agree to these terms of use and that it constitute the complete and exclusive
                                    agreement between us concerning your use of the website, and supersede and govern all prior proposals,
                                    agreements, or other communications.</p>
                                <p className="techdesk-para">We reserve the right, in our sole discretion, to change/ alter/ modify these terms of use at any time by
                                    posting the changes on the website. Any changes are effective immediately upon posting to the website. Your
                                    continued use of the website thereafter constitutes your agreement to all such changed terms of use. We may,
                                    with or without prior notice, terminate any of the rights granted by these terms of use. You shall comply
                                    immediately with any termination or other notice, including, as applicable, by ceasing all use of the
                                    website.</p>
                                <p className="techdesk-para">Nothing contained in these terms of use shall be construed as creating any agency, partnership,
                                    affiliation, joint venture or other form of joint enterprise between us. Our failure to require your
                                    performance of any provision hereof shall not affect our full right to require such performance at any time
                                    thereafter, nor shall our waiver of a breach of any provision hereof be taken or held to be a waiver of the
                                    provision itself. In the event that any provision of these terms of use shall be unenforceable or invalid
                                    under any applicable law or be so held by any applicable arbitral award or court decision, such
                                    unenforceability or invalidity shall not render these terms of use unenforceable or invalid as a whole but
                                    these terms of use shall be modified, to the extent possible, by the adjudicating entity to most fully
                                    reflect the original intent of the parties as reflected in the original provision. The headings in the terms
                                    of use are for convenience only and shall not be used in its interpretation.</p>
                                <p className="techdesk-para">These Terms of Use, and any rights and licenses granted hereunder, may not be transferred or assigned by
                                    you, but may be assigned by Magenta EV Solutions Private Limited without restriction.</p>
                                <h3>Changes to this Policy</h3>
                                <p className="techdesk-para">Magenta, may edit this policy from time to time. If we make any substantial changes, we will notify you by
                                    posting a prominent announcement on our pages. However, we undertake no liability or obligation to inform
                                    each user about the change of this Policy. </p>
                                <h3>Contact Information</h3>
                                <p className="techdesk-para"><b>Customer Service Desk</b></p>
                                <p className="techdesk-para"><b>Email id: </b>

                                    <a href="mailto:sales@magentamobility.com">sales@magentamoblity.com</a></p>
                                <p className="techdesk-para"><b>Phone:</b> <a class="description " href="tel:1800-123-0267">+91 74004 90903 / 1800 123 0267</a> </p>



                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    );
};

export default Policy;
