import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


    const History = () => {
      useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

      const styleObj = {
        color: "#000000",
        fontWeight:'900'
      }
    
      return (
        <>
          <div className='header1'>
            <section className="container techdesk-container pb-5">
              <div className="row flex-column-reverse flex-md-row">
              <div
              data-aos="fade-right"
               className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                  <img
                    src="./images/history.png"
                    alt="heroimg"
                    className="img-fluid"
                  />
              
                </div>
                <div
                data-aos="fade-left"
                className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">
                <p className="subtitle">
                Magenta Mobility HISTORY                  </p>
                  <h2 className="techdesk-title">
                  We Began With Electric 4 Wheelers!
                  </h2>
                  <div className="linePreloader"></div>
                  <p className="techdeskabout-para pt-5">
                  Magenta Mobility first began operations in Bengaluru in the month of January 2021. The initial business model was about moving people using electric cars, but soon we began operating electric 3-wheeler cargo vehicles to provide services to large e-commerce, FMCG and logistics companies.
                  </p>
                  <p className="techdeskabout-para">
                  We kick-started operations with last mile deliveries initially and then soon scaled up to provide first-mile, mid-mile and reverse logistic solutions to numerous customers using electric vehicles.
                  </p>
                  {/* <p className="techdeskabout-para">
                  Our electric 4 wheeler fleet still continues to move people - silently!
                  </p> */}

                </div>
                {/*  --------------- main header right side--------------  */}

              </div>
            </section>
          </div>
        </>
      );
    };


export default History