import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './evetalprogram.css'


const Partners = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>
            <div className="">
                <section className="container techdesk-container probootstrap-section">
                    <div className="row ">
                        <div
                            data-aos="fade-right"
                            className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">

                            <h2 className="techdesk-title">
                            Our Capabilities - Delivery Partners
                            </h2>
                            <p className="techdeskabout-para pt-5">
                            Our Driver Partners are a critical part of the supply chain and we make sure drivers are safe and are provided with the best assistance to enhance their life on the roads. 
Our use of technology increases efficiency, streamlines processes and increases productivity, allowing our driver partners to do what they do best – move products from point A to point B.

                            </p>
                            <ul className="list-group list-para">
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i>  Background and criminal verifications
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i>  Meticulous process for driver onboarding
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> 5 day compulsory and rigorous training and induction
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> Courteous and polite driver partners with zero complaints
                                </li>
                                <li className="list-group-item d-flex">
                                    <i class="fa-solid fa-circle-check p-2"></i> Motivated and aspirational driver partners recognized and felicitated by City Traffic Police
                                </li>
                            </ul>
                        </div>
                        {/*  --------------- main header right side--------------  */}
                        <div
                            data-aos="fade-left"
                            className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                            <img
                                src="./images/driver.png"
                                alt="heroimg"
                                className="img-fluid"
                            />
                         
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};


export default Partners