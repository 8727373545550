import React, { useState } from 'react';
import './agreement.css'

const Pay = () => {
    const retrievedObject = localStorage.getItem('UserData');
    const data = JSON.parse(retrievedObject);
    console.log('retrievedObject: ', JSON.parse(retrievedObject));

    return (
        <>
            <div className="header1 pt-5">
                <section className="contactus-section techdesk-container probootstrap-section" >
                    <div className="container calbox about-bg h-100">
                        <h2>Your Investment</h2>
                        <div className="col-12 col-lg-12 pt-5">
                            <div className="row">
                                <div className="col-3 col-lg-3">     <div className="techdesk-title2">
                                    <h3>
                                        Total Investment
                                    </h3>
                                    <div className="techdesk-result"><strong>{data.investedamount}</strong></div>

                                </div></div>
                                <div className="col-3 col-lg-3 ">
                                    <div className="techdesk-title2">
                                        <h3 >
                                        Monthly repayment
                                        </h3>
                                        <div className="techdesk-result"><strong>{data.monthly}</strong></div>
                                    </div>
                                </div>
                                <div className="col-3 col-lg-3 ">
                                    <div className="techdesk-title2">
                                        <h3 >
                                        Total Interst
                                        </h3>
                                        <div className="techdesk-result"><strong>{data.interest}</strong></div>
                                    </div>
                                </div>
                                <div className="col-3 col-lg-3 ">
                                    <div className="techdesk-title2">
                                        <h3 >
                                        Total return
                                        </h3>
                                        <div className="techdesk-result"><strong>{data.result}</strong></div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='pt-3'>
                            <button
                                type="submit"
                                className="btn btn-style"

                            //  onClick={() => setChecked((c) => !c)}
                            >
                                Pay Amout
                            </button>
                        </div>

                    </div>
                </section>
            </div></>
    )
}

export default Pay