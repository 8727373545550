import React, { useEffect } from "react";
import './login.css';
import AOS from "aos";
import "aos/dist/aos.css";

const Error404 = () => {
	
	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);

	  useEffect(() => {
		AOS.init();
		AOS.refresh();
	  }, []);
  return (
    <>
   <div className="container-fluid login-bg">
		<div className="container-login1001 ">
			<div className="wrap-login1001">
			<div className="row">
            <div data-aos="fade-right" className="col-12 col-lg-12 p-5 d-flex justify-content-center flex-column align-items-center" style={{ overflowX:'hidden',overflowY:'hidden'}} >
			<img
                src="./images/404.png"
                alt="heroimg"
                className="img-fluid"
              />
              {/* <h1 className="techdesk-title text-center">
              404 ERROR
       
              </h1> */}

			  <span className="login100-form-title1">
						Error 404
					</span>
			  <p className="techdeskabout-para text-center">
			  we can't find the page that you are looking for
              </p>
    

              <form class="form-inline my-2 my-lg-0">

                <button onClick={() => window.open("/")} class="header-btn1 my-2 my-sm-0 "><i class="fa-solid fa-home"></i> Back to home</button>
               
                <br />
              </form>




            </div>

			</div>
			</div>
		</div>
	</div>
        </>
  )
}

export default Error404