import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Aboutevet from "./aboutevet";
import Calculator from "../calculator/calulator";
import Clientel from "./clientels";
import Testimonials from "./testimonials";
import Process from "./process";
import Faq from "./faq";
import Ourcapability from "./ourcapability";

const Alprogram = () => {

    const styleObj = {
        color: "#000000",
        fontWeight: '900',
        paddingLeft: "2px",
        paddingRight: "2px",
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>

            <div className="header2 about-bg">
                <section className="container techdesk-container pt-5">
                    <div className="row  pt-5">
                        <div
                            data-aos="fade-right"
                            className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">
                            {/* <p className="subtitle">
                Intelligent Delivery with
                  </p> */}
                            <h2 className="techdesk-title">
                                Opportunity - Clean Mobility in India
                            </h2>
                            {/* <div className="linePreloader"></div> */}
                            <p className="techdeskabout-para pt-5">
                            India’s logistics sector is a 11 lakh crore industry that is expected to grow at 7%:
                            </p>
                            <ul className="list-group list-para">
                                <li className="list-group-item ">
                                    <i class="fa-solid fa-circle-check p-2"></i> Logistics sector represents to <b style={styleObj}> 5% of India’s GDP</b>

                                </li>
                                <li className="list-group-item ">
                                    <i class="fa-solid fa-circle-check p-2"></i> Employs <b style={styleObj}>2.2 crore</b> people

                                </li>
                                <li className="list-group-item ">
                                    <i class="fa-solid fa-circle-check p-2"></i>  India handles <b style={styleObj}>4.6 billion tonnes of goods</b> annually
                                </li>
                                <li className="list-group-item ">
                                    <i class="fa-solid fa-circle-check p-2"></i> Total annual transportation cost of <b style={styleObj}>Rs 9.5 lakh crore </b>
                                </li>
                                <li className="list-group-item ">
                                    <i class="fa-solid fa-circle-check p-2"></i> 22% Agricultural products; 39% Mining products; 39% Manufacturing related commodities
                                </li>
                                <li className="list-group-item">
                                    <i class="fa-solid fa-circle-check p-2"></i> Between 2022 to 2050 energy consumption = 5.8 billion tonnes of oil ; GOI aims to <b style={styleObj}>reduce this by half</b>
                                </li>
                                <li className="list-group-item">
                                    <i class="fa-solid fa-circle-check p-2"></i> India can save <b style={styleObj}>10GT of CO2;</b> 500kT of particulate matters; 15millionT of Nitrogen Oxide
                                </li>
                            </ul>
                            {/* <form class="d-flex">
                   <button class="btn btn-style" type="submit">Read More</button>
                   </form> */}

                        </div>
                        {/*  --------------- main header right side--------------  */}
                        <div
                            data-aos="fade-left"
                            className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                            <img
                                src="./images/alprogram.png"
                                alt="heroimg"
                                className="img-fluid"
                            />
                            {/* <img
                    src="./images/hero4.jpg"
                    alt="heroimg4"
                    className="img-fluid main-hero-img2"
                  /> */}
                        </div>
                    </div>
                </section>
            </div>
            {/* <Aboutevet/> */}
            <Ourcapability/>
            <Calculator/>
            <Process/>
            <Clientel/>
            <Testimonials/>
            <Faq/>


        </>
    );
};


export default Alprogram