import React from 'react';
import Softwaretechnology from "./softwaretechnology";
import Fleet from "./fleet";
import Vehicles from "./vehicles";
import Partners from './partners';

const ourcapability = () => {
    return (
        <>
            <div className='header1'>
                <section className="container techdesk-container ">
                    <div className="row">
                        <div className="col-12 col-lg-12 header-left-side d-flex justify-content-center flex-column align-items-center ">
                            <h2 className="techdesk-title">
                                Our Capabilities
                            </h2>
                        </div>
                    </div>
                </section>



                <section className="container ">
                    <div className="row">
                        <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Software Technology</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Fleet Charging Stations</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Vehicles</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-partner" type="button" role="tab" aria-controls="pills-partner" aria-selected="false">Delivery Partners</button>
                            </li>

                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"><Softwaretechnology /></div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"><Fleet /></div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"><Vehicles /></div>
                            <div class="tab-pane fade" id="pills-partner" role="tabpanel" aria-labelledby="pills-partner-tab"><Partners /></div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default ourcapability