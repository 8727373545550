import React, { useState } from "react";
import './calculator.css';
import { useNavigate } from "react-router-dom";


const Calculator2 = () => {
    let navigate = useNavigate();


    const [vehicles, setVehicles] = useState(0);
    // const [years, setYears] = useState(3);
    const [interest, setInterest] = useState(0);
    const [finterest, setFinterest] = useState(0);
    const [result, setResult] = useState(0);
    const [fixedd, setFixedd] = useState(0);
    const [cbondinterest, setCbondinterest] = useState(0);
    const [cbond, setCbond] = useState(0);
    const [niftyinterest, setNiftyinterest] = useState(0);
    const [nifty, setNifty] = useState(0);

    const rateofinterest = (e) => {
        // Magenta EVET return
        e.preventDefault();

        const interest = vehicles * 30 / 100 * 3;
        const finterest = vehicles * 5.4 / 100 * 3;
        const cbondinterest = vehicles * 9.2 / 100 * 3;
        const niftyinterest = vehicles * 13 / 100 * 3;
        setInterest(interest);
        const result = vehicles + interest;
        setResult(result);
        //fixed deposite return
        setFinterest(finterest);
        const fixedd = vehicles + finterest;
        setFixedd(fixedd);
        //Corporate Bond return
        setCbondinterest(cbondinterest);
        const cbond = vehicles + cbondinterest;
        setCbond(cbond);
        //Nifty 50 return
        setNiftyinterest(niftyinterest);
        const nifty = vehicles + niftyinterest;
        setNifty(nifty);
    };

    return (
        <>
            <div className="header1 ">
                <div className="container calbox about-bg">
                    <form>
                        <div className="row">
                            <div className=" col-12 col-lg-12 header-left-side d-flex justify-content-center flex-column align-items-center techdesk-container">
                                <h2 className="techdesk-title">
                                    Magenta Mobility's Asset Leasing Programme - Features and Benefits
                                </h2>
                                <p className="techdeskabout-para pt-5">
                                    Under the Magenta Mobility's Asset Leasing programme, invest and be part of the booming EV ecosystem and generate risk-free high returns on your investments
                                </p>

                            </div>
                            {/* <div className="col-12 col-lg-6">
                                <img
                                    src="./images/calculator.png"
                                    alt="heroimg"
                                    className="img-fluid"
                                />
                            </div> */}
                            <div className="col-12 col-lg-3 ">
                                <div className="col-12 col-lg-12 contact-input-feild1 contact-rightside">
                                    <h2>Number of vehicles <strong>{vehicles}</strong></h2>
                                    <h2>0 <input type="range" step="1" min="0" max="20"
                                        onChange={(e) => {
                                            setVehicles(e.target.value);
                                            // setVehicles(e.target.value * 300000); 
                                            // rateofinterest(e);    

       
                                        }}
                                        id='ex1Slider'
                                    /> 20 </h2>
                                </div>
                                <div className="col-12 col-lg-12 contact-input-feild1 contact-rightside">
                                    <h2>Rate of Intrest</h2>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 %"
                                        // onChange={(e) => setInterest(e.target.value)}
                                        disabled
                                    />
                                </div>
                                <h2>Number of vehicles <strong>{vehicles}</strong></h2>
                                    <h2>0 <input type="range" step="1" min="0" max="20"
                                        onChange={(e) => {
                                            setVehicles(e.target.value);
                                            // setVehicles(e.target.value * 300000); 
                                            // rateofinterest(e);    

       
                                        }}
                                        id='ex1Slider'
                                    /> 20 </h2>
                                <div className="row">
                                    <div className="col-12 col-lg-12 contact-input-feild1 contact-rightside">
                                        <h2>Duration</h2>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="1 years"
                                            // onChange={(e) => setYears(e.target.value)}
                                            disabled
                                        />
                                    </div>
                                    <button onClick={() => { navigate("../authentication", { replace: true }) }} class="header-btn1 my-2 my-sm-0 ">Next</button>
                                </div>
                                {/* <button
                                    type="submit"
                                    className="btn btn-style w-100"
                                    onClick={(e) => {
                                        rateofinterest(e);
                                    }}
                                >
                                    Calculate
                                </button> */}
                            </div>

                            <div className="col-12 col-lg-2">
                                <div className="">

                                    <div className="techdesk-title2">
                                        <h2>
                                            Total Investment
                                        </h2>
                                        <div className="techdesk-result"><strong>₹ {vehicles * 300000}</strong></div>
                                    </div>
                                    <div className="techdesk-title2">
                                        <h2 >
                                            Total Interst
                                        </h2>
                                        <div className="techdesk-result"><strong>₹ {interest}</strong></div>
                                    </div>
                                    <div className="techdesk-title2">
                                        <h2 >
                                            Total return
                                        </h2>
                                        <div className="techdesk-result"><strong>₹ {result}</strong></div>
                                    </div>


                                </div>

                            </div>
                            <div className="col-12 col-lg-7">
                                <div class="container table-responsive rounded">
                                    <table class="table table-bordered  table-hover">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"><h2>IRR</h2></th>
                                                <th scope="col"><h2>Repayment Cycle</h2></th>
                                                <th scope="col"><h2>Tenure</h2></th>
                                                <th scope="col"><h2>Total Payout</h2></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row"><span style={{ color: "#066ac9" }}>Magenta EVET</span> </th>
                                                <td><span style={{ color: "#066ac9" }}>10%</span>	</td>
                                                <td><span style={{ color: "#066ac9" }}>Bi-Annualy</span>	</td>
                                                <td><span style={{ color: "#066ac9" }}>3</span></td>
                                                <td><span style={{ color: "#066ac9" }}>{result}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Fixed Deposit</th>
                                                <td>5.4%</td>
                                                <td>Yearly</td>
                                                <td>3</td>
                                                <td>{fixedd}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">A+ Grade Corporate Bond	</th>
                                                <td>9.2%</td>
                                                <td>Bi-Annualy</td>
                                                <td>3</td>
                                                <td>{cbond}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Nifty 50 Index(Last 3 Years)</th>
                                                <td>13%</td>
                                                <td>End of Life</td>
                                                <td>3</td>
                                                <td>{nifty}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </>
    )
}

export default Calculator2