import React, { useEffect, useState } from "react"; import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import '../calculator/otpmodel.css';
import { Button, Modal } from 'react-bootstrap';

const Authentication = () => {


    // model code
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let navigate = useNavigate();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        file: ""
    });
    const [file, setFlie] = useState();
    let name, value;
    const postUserData = (event) => {
        name = event.target.name;
        value = event.target.value;
        setUserData({ ...userData, [name]: value });
    };

    // connect with firebase
    const submitData = async (event) => {
        event.preventDefault();
        handleShow();
        const { firstName, lastName, phone, email, file } = userData;

        // if (!firstName || !lastName || !phone || !email) {
        //     toast.error("Please enter details");

        // }
        // else {
        //     const res = fetch(
        //         "https://evetweb-b689c-default-rtdb.firebaseio.com/userDataRecords.json",
        //         {
        //             method: "POST",
        //             headers: {
        //                 "Content-Type": "application/json",
        //             },
        //             body: JSON.stringify({
        //                 firstName,
        //                 lastName,
        //                 phone,
        //                 email,
        //                 file,

        //             }),
        //         }
        //     );

        //     if (res) {
        //         setUserData({
        //             firstName: "",
        //             lastName: "",
        //             phone: "",
        //             email: "",
        //         });
        //         toast.success("Form submitted successfully");
        //     } else {
        //         toast.error("Please enter details");
        //     }
        // }

        // if (firstName && lastName && phone && email && address && message) {
        //     const res = fetch(
        //         "https://evetwebsite-default-rtdb.firebaseio.com/userDataRecords.json",
        //         {
        //             method: "POST",
        //             headers: {
        //                 "Content-Type": "application/json",
        //             },
        //             body: JSON.stringify({
        //                 firstName,
        //                 lastName,
        //                 phone,
        //                 email,
        //                 address,
        //                 message,
        //             }),
        //         }
        //     );

        //     if (res) {
        //         setUserData({
        //             firstName: "",
        //             lastName: "",
        //             phone: "",
        //             email: "",
        //             address: "",
        //             message: "",
        //         });
        //         alert("Form Submitted");
        //     } else {
        //         alert("plz fill the data");
        //     }
        // } else {
        //     alert("plz fill the data");
        // }
    };

    return (
        <>
            <div className="header2 about-bg">

                <section className="contactus-section techdesk-container probootstrap-section ">
                    <div className="container ">
                        <ToastContainer position="top-center" />
                        <div className="row pt-5">
                            <div className="col-2 col-lg-2 "></div>
                            <div
                                data-aos="fade-left"
                                className="contact-rightside col-12 col-lg-8 p-5 header-left-side d-flex justify-content-center flex-column">

                                <form onSubmit={submitData}>
                                    <div className="row text-center">
                                        <div className="contact-container ">
                                            <h2 className="techdesk-title">
                                                Hello!</h2>
                                            <p className="techdesk-para">
                                                An incredible investment return experience is waiting for you </p>
                                        </div>
                                        <div className="col-12 col-lg-8 contact-input-feild ">
                                            <p className="techdesk-para">
                                                Mobile Number </p>

                                            <input
                                                type="number"
                                                name="contactnumber"
                                                id=""
                                                className="form-control"
                                                placeholder="+91"
                                                // value={userData.firstName}
                                                onChange={postUserData}
                                            />
                                        </div>


                                        <div className="col-12 col-lg-8 contact-input-feild ">
                                            <button
                                                type="submit"
                                                className="btn btn-style w-100"
                                            // onClick={submitData}
                                            >
                                                Continue
                                            </button>
                                        </div>
                                        <div className="col-12 col-lg-4 contact-input-feild "></div>
                                    </div>


                                </form>
                            </div>
                            <div className="col-2 col-lg-2 "></div>
                        </div>
                    </div>

                </section>

            </div>




            {/* model section */}
            <Modal size="md-down" show={show} onHide={handleClose}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div class="container height-100 d-flex justify-content-center align-items-center">
                        <div class="position-relative">
                            <div className=" p-2 text-center">
                                <p className="techdeskabout-para">Please enter the one time password <br /> to verify your account</p>
                                <div> <span className="techdeskabout-para">A code has been sent to</span> <small className="techdeskabout-para">*******9897</small> </div>
                                <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                                     <input class="m-2 text-center form-control rounded" type="text" value="6" id="first" maxlength="1" /> 
                                     <input class="m-2 text-center form-control rounded" type="text" value="5" id="second" maxlength="1" />
                                      <input class="m-2 text-center form-control rounded" type="text" value="9" id="third" maxlength="1" /> 
                                      <input class="m-2 text-center form-control rounded" type="text" value="9" id="fourth" maxlength="1" /> 
                                      <input class="m-2 text-center form-control rounded" type="text" value="7" id="fifth" maxlength="1" /> 
                                      <input class="m-2 text-center form-control rounded" type="text" value="5" id="sixth" maxlength="1" /> </div>

                                <div className="mt-4"> <button class="btn btn-style w-100"  onClick={() => { navigate("../investordetails", { replace: true }) }} >Continue</button> </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Authentication