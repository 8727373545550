import React,{useEffect} from "react";
import Hero from './hero';
import Techdesk from './techdesk';
import Commute from './commute';
import Mobility from './mobility';
import Clients from './clients';
import News from './news';
import Servicescard from '../services/servicescard'

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <>
      < Hero/>
      <News />
      < Servicescard/>
      < Techdesk/>
      < Mobility/>
      < Commute/>
      < Clients />
      {/* <AnnuityCalculator /> */}
      {/* < Testimonials /> */}
      </>
  )
}

export default Home