import React, { useEffect } from "react";
import './about.css';
import AOS from "aos";
import "aos/dist/aos.css";

const Team2 = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <>
            <div className='header1' >
                <section className="container techdesk-container probootstrap-section">
                    <div className="row">
                        <div className="col-12 col-lg-12 header-left-side d-flex justify-content-center flex-column align-items-center ">
                            {/* <p className="subtitle">
                                MEET THE TEAM

                            </p> */}
                            <h2 className="techdesk-title">
                            Leadership Team
                            </h2>
                            <div className="linePreloader1"></div>
                            <p className="techdesk-para text-center">
                                We are a growing team of logistics professionals and thought leaders - full of personality, proficiency and fun with employee statistics that lists both skills and “superpowers”. We’re the wearer of many hats dedicated to helping companies achieve their net zero goals and decarbonizing the logistics industry.
                            </p>


                        </div>
                        {/*  --------------- main header right side--------------  */}
                        <div className="col-12 col-lg-12 header-right-side d-flex justify-content-center align-items-center main-herosection-images probootstrap-section">
                            <div class="row clearfix">
                                <div
                                data-aos="fade-right"
                                class="team-block col-lg-3 col-md-3 col-sm-12 pt-5">
                                    <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        {/* <ul class="social-icons">
                                            <li><a href="https://www.facebook.com/MagentaPvtLtd" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://twitter.com/MagentaPvtLtd" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/magentapvtltd/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/magentagroup/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                        </ul> */}
                                        <div class="image">
                                            <a href="https://www.linkedin.com/in/maxson-lewis-201bb520/" target="_blank"><img src="/images/maxonsir.png" alt="" /></a>
                                        </div>
                                        <div class="lower-content">
                                            <h3><a href="https://www.linkedin.com/in/maxson-lewis-201bb520/" target="_blank">MAXSON LEWIS </a></h3>
                                            <div class="designation">Managing Director </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                data-aos="fade-up"
                                class="team-block col-lg-3 col-md-3 col-sm-12 pt-5">
                                    <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        {/* <ul class="social-icons">
                                        <li><a href="https://www.facebook.com/MagentaPvtLtd" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://twitter.com/MagentaPvtLtd" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/magentapvtltd/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/magentagroup/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                        </ul> */}
                                        <div class="image">
                                            <a href="https://www.linkedin.com/in/dddias/" target="_blank"><img src="/images/darrylsir.png" alt="" /></a>
                                        </div>
                                        <div class="lower-content">
                                            <h3><a href="https://www.linkedin.com/in/dddias/" target="_blank">DARRYL DIAS </a></h3>
                                            <div class="designation">Director Operations </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                data-aos="fade-up"
                                class="team-block col-lg-3 col-md-3 col-sm-12 pt-5">
                                    <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        {/* <ul class="social-icons">
                                        <li><a href="https://www.facebook.com/MagentaPvtLtd" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://twitter.com/MagentaPvtLtd" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/magentapvtltd/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/magentagroup/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                        </ul> */}
                                        <div class="image">
                                            <a href="https://www.linkedin.com/in/rohit-saily-5569834/" target="_blank"><img src="/images/rohitsaily.png" alt="" /></a>
                                        </div>
                                        <div class="lower-content">
                                            <h3><a href="https://www.linkedin.com/in/rohit-saily-5569834/" target="_blank">ROHIT SAILY </a></h3>
                                            <div class="designation">Business Head</div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                data-aos="fade-left"
                                class="team-block col-lg-3 col-md-3 col-sm-12 pt-5">
                                    <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        {/* <ul class="social-icons">
                                        <li><a href="https://www.facebook.com/MagentaPvtLtd" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://twitter.com/MagentaPvtLtd" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/magentapvtltd/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/magentagroup/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                        </ul> */}
                                        <div class="image">
                                            <a href="#" target="_blank"><img src="/images/manjunath.png" alt="" /></a>
                                        </div>
                                        <div class="lower-content">
                                            <h3><a href="#" target="_blank">MANJUNATH K S </a></h3>
                                            <div class="designation">Sr. Manager</div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Team2