import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import fleet from "../../../public/images/fleet.gif"

const Clients = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className="header2">
        <section className="container pt-5">
          <div className="row ">
            <div class="container clients">
              <div class="row">
              <div
              data-aos="fade-right"
               class="col-md-2 col-6 ">
                  <img class="fixed-ratio-resize item" src="images/logos/amazon.png" alt="logo" />
                </div>
                <div
                data-aos="fade-right"
                 class="col-md-2 col-6">
                  <img class="fixed-ratio-resize item" src="images/logos/bigbasket.png" alt="logo" />
                </div>
                <div
                data-aos="fade-up"
                class="col-md-2 col-6">
                  <img class="fixed-ratio-resize item" src="images/logos/flipkart.png" alt="logo" />
                </div>
                <div
                data-aos="fade-up"
                class="col-md-2 col-6">
                  <img class="fixed-ratio-resize item" src="images/logos/porter.png" alt="logo" />
                </div>
                <div
                data-aos="fade-left"
                class="col-md-2 col-6">
                  <img class="fixed-ratio-resize item" src="images/logos/uddan.png" alt="logo" />
                </div>
                <div
                data-aos="fade-left"
                class="col-md-2 col-6 logo">
                  <img class="fixed-ratio-resize item" src="images/logos/ola.png" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Clients;
