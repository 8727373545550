import React, { useEffect } from "react";
import '../about/about.css';
import AOS from "aos";
import "aos/dist/aos.css";

const Faq = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className='header1' >
        <section className="container techdesk-container probootstrap-section">
          <div className="row">
            <div className="col-12 col-lg-12 align-items-center ">

              <h2 className="techdesk-title">
                Magenta Mobility's Asset Leasing Programme - Frequently Asked Questions
              </h2>
            </div>
          </div>
        </section>


        <section className="container pb-5">
          <div className="row">
            <div className="col-12 col-lg-6 align-items-center ">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      1) What is Magenta Mobility's Asset Leasing programme?

                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Magenta Mobility's AL stands for Magenta Mobility's Asset Leasing. Magenta Mobility's Asset Leasing is a technology driven investment program for retail & corporate investors. This is an alternate investment product which is non-market linked, low-risk generating high returns to investors.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      2) Who can invest in Magenta Mobility's Asset Leasing programme?
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">You can invest as an individual or in the name of the registered enterprise you own.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      3) Why should I invest in Magenta Asset Leasing Programme?
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Magenta Mobility’s Asset Leasing Programme comes with the following benefits:

                      <ul>
                        <li>(i) Safe & free from market-risks</li>
                        <li>(ii) Fixed and assured rate of return</li>
                        <li>(iii) High returns promised at 15% post tax (about 22% pre-tax)</li>
                        <li>(iv) Customize frequency of returns - Monthly, Quarterly, Semi-Annually or Annually </li>
                        <li>(v) Best source of passive income</li>
                      </ul>

                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      4) Is the IRR fixed?
                    </button>
                  </h2>
                  <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Yes. IRR is fixed at 15% post tax. Just to compare, for an investment between April 2021 to March 2022 (FY21/22), in Nifty you would have got a return of 17%, in real estate you would have got a return of 7%, gold would have yielded you a return of 8%, Fixed deposits at 10% and Mutual Fund at 13%.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      5) Is there a minimum and maximum limit of investments?
                    </button>
                  </h2>
                  <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">We are not a Crowd-fund organisation and our focus is not on sourcing funds. Hence, to continue minimal focus on sourcing funds, we expect a Minimum investment of at least Rs 20,00,000 (20 Lakhs) and maximum of Rs 10,00,00,000 (10 Crores)</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                      6) Can I get refund once invested? Can I prematurely withdraw the invested amount?
                    </button>
                  </h2>
                  <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Since the investment you make shall be used to fund a physical asset, refund or premature withdrawal once invested shall not be possible.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 align-items-center ">
              <div class="accordion accordion-flush" id="accordionFlushExample">



                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSeven">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                      7) Can I re-invest the Monthly Recurring Returns?
                    </button>
                  </h2>
                  <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">We offer straight-line method and non-compounding returns. Your monthly recurring returns cannot be re-invested. Yes, at the end of the tenure, you can invest the principal and the interest amount both.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingEight">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                      8) Is my investment safe?
                    </button>
                  </h2>
                  <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Investments made by you are not subjected to any market risks. Magenta Mobility’s AL programmed is the safest investment.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingNine">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                      9) Can I invest in cash?
                    </button>
                  </h2>
                  <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Yes. Cash investments up to Rs 50,000 per investor, per financial year can be made. However, the returns shall be transferred electronically to the bank account details provided and linked.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTen">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                      10) What if the asset financed by me is unutilized? 
                    </button>
                  </h2>
                  <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">You do not have to worry about asset’s performance and utilization. Once financed by you and leased by us, we take care of day-to-day maintenance, operations and utilization of the assets. Irrespective of the asset’s utilization, you continue to receive the returns.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingEleven">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                      11) Can I track / check assets leased by me? 
                    </button>
                  </h2>
                  <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Since our operations are widespread and across multiple states, tracking assets and physically checking may not be possible.</div>
                  </div>
                </div>
                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingtwelve">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwelve" aria-expanded="false" aria-controls="flush-collapsetwelve">
                      12) Is my investment safe?
                    </button>
                  </h2>
                  <div id="flush-collapsetwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingtwelve" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Any individual above 18 years of age, is Indian resident with valid Aadhar card & PAN card can participate in EVET AL programme.</div>
                  </div>
                </div> */}

              </div>
            </div>

          </div>
        </section>


      </div>
    </>
  )
}

export default Faq