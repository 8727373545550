import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './evetalprogram.css'


const Clientel = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>
            <div className="header1">
                <section className="container techdesk-container probootstrap-section">
                    <div className="row">
                        <div className="col-12 col-lg-12 header-left-side d-flex justify-content-center flex-column align-items-center ">

                            <h2 className="techdesk-title pb-5">
                                Our Customers
                            </h2>
                        </div>
                    </div>
                </section>

                <section className="container">
                    <div className="row">

                        <div className="col-12 col-lg-12">
                            <div className="row">
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/amazon.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/bigbasket.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/wipro.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/porter.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/jambotail.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/carzone.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 col-12 col-lg-12">
                            <div className="row">
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/onemg.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/flipkart.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/elior.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/kadambass.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/sprink.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/mahindra.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 col-12 col-lg-12">
                            <div className="row">
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/starquick.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/pandg.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/pharmeasy.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/armann.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/uddan.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-2 " id="services1">
                                    <div class="box">
                                        <img
                                            src="./images/clients/chotabeta.png"
                                            alt="heroimg"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>



                </section>
            </div>
        </>
    );
};


export default Clientel