import React,{useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";


    const Commute = () => {
      useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      return (
        <>
      

          <div className="header1">
            <section className="container techdesk-container probootstrap-section">
              <div className="row ">

                <div
                data-aos="fade-right"
                 className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">
                {/* <p className="subtitle">
                DATA BACKED DECISIONS
                  </p> */}

                  <h2 className="techdesk-title">
                  Data Backed Dashboard View
              </h2>
              <div className="linePreloader"></div>


                  <p className="techdeskabout-para pt-5">
                  With data as our third-eye, our dashboard view:
                  </p>
                  <ul className="list-group list-para">
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Helps view entire end to end delivery and fleet management services.
                
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Promises higher SLA adherence with impeccable customer support.  
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i> Provides insights to improve business operations. 
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Increases levels of accuracy.  
                </li>
              </ul>
                </div>
                {/*  --------------- main header right side--------------  */}
                <div
              data-aos="fade-left"
               className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                  <img
                    src="./images/dashboard2.png"
                    alt="heroimg"
                    className="img-fluid"
                  />
                  {/* <img
                    src="./images/hero4.jpg"
                    alt="heroimg4"
                    className="img-fluid main-hero-img2"
                  /> */}
                </div>
              </div>
            </section>
          </div>




          
        </>
      );
    };


export default Commute