import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './evetalprogram.css'


const Fleet = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>
            <div className="">
                <section className="container techdesk-container probootstrap-section">
                    <div className="row ">
                        <div
                            data-aos="fade-right"
                            className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">

                            <h2 className="techdesk-title">
                                Our Capabilities - Fleet Charging Stations
                            </h2>
                            <p className="techdeskabout-para pt-5">
                                India's Largest Network of 5000+ ev chargers*
                            </p>
                            <div className="row">
                            <div class="col-md-6 col-lg-4 " id="services1">
                                <div class="box">
                                    <h4 class="title">20+ </h4>
                                    <p class="description text-center">States and union territories</p>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 " id="services1">
                                <div class="box">
                                    <h4 class="title">40+ </h4>
                                    <p class="description text-center">Majour cities across India</p>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 " id="services1">
                                <div class="box">
                               <h4 class="title">1600+ </h4>
                                <p class="description text-center">DC Fast Chargers installed</p>
                                </div>
                            </div>
                            </div>

                            <div className="row">
                            <div class="col-md-6 col-lg-4 " id="services1">
                                <div class="box">
                                    <h4 class="title">1200+ </h4>
                                    <p class="description text-center">Chargers to be installed at retail fuel outlets</p>
                                </div>
                                
                            </div>
                            <div class="col-md-6 col-lg-4 " id="services1">
                                <div class="box">
                                    <h4 class="title">4000+ </h4>
                                    <p class="description text-center">Chargers to be intstalled at public places</p>
                                </div>
                                
                            </div>
                            <div class="col-md-6 col-lg-4 " id="services1">
                                <div class="box">
                                    <h4 class="title">50000+ </h4>
                                    <p class="description text-center">Electric vehicles charge capacity per day</p>
                                </div>
                                
                            </div>
                            
                            </div>
                            
                            <p className="techdeskabout-para pt-5">
                                Range of DC chargers netwrok : 15kW to 142 kW</p>
                            <p className="techdeskabout-para">
                                Range of AC chargers netwrok : 3.3 kW to 22 kW</p>
                        </div>
                        {/*  --------------- main header right side--------------  */}
                        <div
                            data-aos="fade-left"
                            className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                            <img
                                src="./images/fleetcharge.png"
                                alt="heroimg"
                                className="img-fluid"
                            />
                            {/* <img
                    src="./images/hero4.jpg"
                    alt="heroimg4"
                    className="img-fluid main-hero-img2"
                  /> */}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};


export default Fleet