import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


    const Softwaretechnology = () => {
      useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

      const styleObj = {
        color: "#000000",
        fontWeight:'900'
      }
    
      return (
        <>
          <div className=''>
            <section className="container techdesk-container pb-5">
              <div className="row flex-column-reverse flex-md-row">
              <div
              data-aos="fade-right"
               className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                  <img
                    src="./images/software.png"
                    alt="heroimg"
                    className="img-fluid"
                  />
              
                </div>
                <div
                data-aos="fade-left"
                className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">

                <h2 className="techdesk-title">
                Our Capabilities - Software Technology
              </h2>
                <p className="techdeskabout-para pt-5">
                Magenta Mobility is a technology focused and data driven operations unit. We provide end-to-end connected mobility solutions to better manage our remote assets, vehicles and personnel. With our full suite of solutions covering the telematics, we have built our state-of-the-art platform.
                  </p>
                  <ul className="list-group list-para">
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Improve fleet visibility and control                
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Geo-fence, Overspeed Alerts, Remote Immobilize, LIVE location, Safety Alerts
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i> Analyse fleet performance 
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Prevent consequential damages 
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Monitor driver behaviour
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  Monitor battery SOC (%) and predict routes

                </li>
              </ul>

                </div>
                {/*  --------------- main header right side--------------  */}

              </div>
            </section>
          </div>
        </>
      );
    };


export default Softwaretechnology