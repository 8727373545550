import React, { useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import Navbar from './header/navbar';
import Home from './components/home/home';
import About from './components/about/about';
import Footer from './footer/footer';
import Services from './components/services/services';
import Contact from './components/contact/contact';
import Terms from './components/terms/terms';
import Policy from './components/terms/policy';
import Disclaimer from './components/terms/disclaimer';
import Login from './components/home/login';
import Error404 from './components/home/error404';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import Calculator from './components/calculator/calulator';
import Alprogram from './components/program/evetalprogram';
import Investordetails from './components/investors/investordetails';
import Agreement from './components/investors/agreement';
import Authentication from './components/investors/authentication';
import Otpmodel from './components/calculator/otpmodel';
import Pay from './components/investors/pay';
import Calculator2 from './components/calculator/calculator2';

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-B37L8CT1Q0');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])


  return (
    <>
      <Router>
        <Navbar />
        <Helmet>
        <title>Electric Vehicles for your Transportation Needs - EVET</title>
        <meta name="description" content="EVET - To build a new India, we need to take small steps to lead big electric fleet for transportation and logistics." />
        <meta name="keywords" content="Local Cab, Reliable cab, Safe cab, cab service, electric cab, cab service, electric cars, electric taxi, airport taxi, Smart Fleet App, Safe cab, Sanitized Cab, Driver cockpits"/>
        </Helmet>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          {/* <Route exact path="/Services" element={<Services />} /> */}
          {/* <Route exact path="/Contact" element={<Contact />} /> */}
          <Route exact path="/Terms" element={<Terms />} />
          <Route exact path="/Policy" element={<Policy />} />
          <Route exact path="/Disclaimer" element={<Disclaimer />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/calculator" element={<Calculator />} />
          <Route exact path="/calculator2" element={<Calculator2 />} />
          <Route exact path="/evetalprogram" element={<Alprogram />} />
          <Route exact path="/investordetails" element={<Investordetails />} />
          <Route exact path='/agreement' element={<Agreement/>} />
          <Route exact path='/authentication' element={<Authentication/>} />
          <Route exact path='/otpmodel' element={<Otpmodel/>} /> 
          <Route exact path='/pay' element={<Pay/>} /> 




          <Route path="*" element={<Error404 />} />

        </Routes>
        <Footer />
      </Router>


    </>
  )
}

export default App