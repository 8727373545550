import React, { useState } from 'react';
import './agreement.css';
import { useNavigate } from "react-router-dom";

const Agreement = () => {
    let navigate = useNavigate();
    const [checked, setChecked] = useState(false);

    return (
        <>
            <div className="header1 pt-5">
                <section className="contactus-section techdesk-container probootstrap-section" >
                    <div className="container calbox about-bg h-100">
                        <h4>LEASE AGREEMENT </h4>
                        <div id="example">

                            <p> This Lease Agreement (“Agreement”) is entered into on this ___ day of June 2022 (“Effective Date”) at ______ by and between:</p>

                            <p>  [Please insert name], an adult Indian inhabitant having PAN number [please insert] and having his/her permanent address at [please insert] (hereinafter referred to as the “Lessor” which expression shall unless it be repugnant to the meaning or context thereof be deemed to mean and include his/her successors, permitted assigns, representatives and legal heirs) of the ONE PART;</p>

                            <p>  AND</p>

                            <p>MAGENTA EV SOLUTIONS PRIVATE LIMITED, a company incorporated under the Companies Act, 2013, bearing CIN U40107MH2018PTC305106 and having its registered office at Plot No. D-285, TTC Industrial Area, MIDC, Turbhe, Navi Mumbai – 400 705, Maharashtra, India, through the hand of its [●], [●] duly authorized in this regard vide Board Resolution dated [date of board resolution], hereinafter referred to as the “Lessee” (which expression shall, unless it be repugnant to the context or meaning thereof, be deemed to mean and include its successors and permitted assigns) of the OTHER PART.</p>

                            <p>The Lessor and the Lessee shall wherever the context so requires individually be referred to as a “Party” and collectively as the “Parties”.</p>

                            <p className='pt-3'>WHEREAS:</p>
                            <ul>


                                <li> A.	The Lessor has expressly represented and warranted to the Lessee that the Lessor is the sole, exclusive, legal and absolute owner and in exclusive possession of the Asset(s) (defined below).</li>

                                <li>B.	The Lessee is inter-alia engaged in the business of electric vehicles (“EV”), EV charging infrastructure, EV mobility solutions and other clean energy allied services ("Business”).</li>

                                <li>C.	The Lessor has represented to the Lessee that he/ she has the required Asset(s) that the Lessee requires for its Business and based on the representations and warranties of the Lessor, the Lessee hereby agrees to take on lease the Asset(s).</li>

                                <li>D.	The Lessor hereby leases the Assets to the Lessee for the Lessee’s exclusive use in accordance with the terms and conditions of this Agreement.</li>

                                <li>E.	The Parties expressly agree that these Recitals shall form an integral part of this Agreement.</li>
                            </ul>
                            <p> NOW THIS AGREEMENT WITNESSETH AND WITH THE PARTIES AGREEING TO BE LEGALLY BOUND BY THE TERMS OF THIS AGREEMENT IT IS HEREBY AGREED BY AND BETWEEN THE PARTIES AS FOLLOWS:</p>

                            <p className='pt-3'>1.DEFINITIONS</p>
                            <ul>
                                <li>1.1	“Applicable Law(s)” shall mean all applicable laws, by-laws, rules, regulations, orders, ordinances, protocols, codes, guidelines, policies, notices, directions, judgments, decrees or other requirements or official directive of any Government Authority, whether in effect on the Effective Date or thereafter.</li>

                                <li>1.2	“Asset(s)” shall mean the movable assets, the details of which are more particularly set out in Annexure A (Details of the Leased Asset(s)), which will be leased by the Lessor to the Lessee exclusively pursuant to this Agreement.</li>

                                <li>1.3	“Authorized Dealer(s)” shall mean the establishment including the manufacturer showroom/ workshop/ repair shop engaged in the sale/service of automobiles or automobile parts, designated and identified by the Lessor.</li>

                                <li>1.4	“Government Authority” means any governmental or statutory authority, government department, agency, commission, board, tribunal or court or other entity authorized to make laws, rules or regulations or pass directions having or purporting to have jurisdiction or any state or other subdivision thereof or any municipality, district or other subdivision thereof having jurisdiction pursuant to the Applicable Laws, including but not limited to any authority which has, or would have, any jurisdiction in relation to the business activities of the Lessor and/or the Lessee;</li>

                                <li>1.5	“Insurance” shall mean the comprehensive insurance in respect of the Asset(s) covering all risks which shall include but not be limited to:</li>

                                <li>i.	loss or damage against natural calamities including but not limited to fire, explosion, self-ignition or lightning, earthquake, flood, typhoon, hurricane, storm, tempest, inundation, cyclone, hailstorm, frost, landslide, rockslide;</li>

                                <li>ii.	loss or damage against man – made calamities such as burglary, theft, riot, strike, malicious act, accident by external means, terrorist activity, any damage in transit by road, rail, inland waterway, lift, elevator or air;</li>

                                <li>iii.	any third-party liability, bodily injury and death and liability for third party property damage.</li>

                                <li>1.6	“Insurance Policy” shall mean the document issued by the Insurer evidencing fact of Insurance of the Asset(s) and its validity and terms of Insurance mentioned in the said document.</li>

                                <li>1.7	“Insurer” shall mean the company duly registered with Insurance Regulatory Development Authority and engaged in the business of providing general insurance i.e., insurance other than life insurance that is acceptable to the Lessee and has issued the Insurance Policy.</li>

                                <li>1.8	“Lease Commencement Date” shall mean the day on which the Asset(s) are handed over to the Lessee by the Lessor for possession and exclusive use by the Lessee.</li>

                                <li>1.9	“Lease End Date” shall mean the day of expiry of the Lease Term.</li>

                                <li>1.10	“Lease Rent” shall mean the periodic payment specified in Annexure B to be paid by the Lessee for the use the of Asset(s) during the Lease Term.</li>

                                <li>1.11	“Lease Term” shall mean the term for which the Asset(s) are given on lease for the exclusive possession and use of the Lessee by the Lessor, and which shall begin on the Lease Commencement Date and end on the Lease End Date.</li>

                                <li>1.12	“Normal Wear and Tear” shall mean expected decline in the condition of the Asset(s) due to normal everyday use that occurs in the course of using the Asset(s).</li>

                                <li>1.13	“Superdari Event” shall mean an event when any Asset is impounded by any Government Authority under the third-party liability or otherwise and is released on a bond with a condition that it cannot be sold/ transferred before settlement of the relevant case.</li>

                            </ul>

                            <p className='pt-3'>2.	INTERPRETATION</p>
                            <ul>
                                <li>2.1	Reference to a gender shall include all other genders and reference to singular includes the plural and vice versa.</li>

                                <li>2.2	Reference to a person includes an individual, company, partnership, or unincorporated association (whether or not having a separate legal personality).</li>

                                <li>2.3	The table of contents and headings used in this Agreement are for reference purposes only and shall not in any way affect the meaning or interpretation of this Agreement.</li>

                                <li>2.4	The term “day” used herein shall mean a calendar day, except where a business day is explicitly stated.</li>

                                <li>2.5	Reference to a company includes any company, corporation or a body-corporate, wherever incorporated.</li>

                                <li>2.6	Reference to a claim includes any claim, demand, action or proceeding of any kind, actual or contingent.</li>

                                <li>2.7	Reference to a statute or statutory provision or any subordinate legislation made from time to time under that statute or statutory provision which is in force at the date of this Agreement; includes that statute or provision as from time to time modified, re-enacted or consolidated whether before or after the date of this Agreement.</li>

                                <li>2.8	Reference to any deed or document includes a reference to the amendments thereto.</li>

                                <li>2.9	The Parties expressly agree that in construction of any terms of this Agreement, the same shall not be construed against the Party drafting the Agreement and/ or any such term.</li>
                            </ul>


                            <p className='pt-3'> 3.	SCOPE</p>
                            <ul>
                                <li>3.1	The Lessor agrees to lease the Asset(s) exclusively to the Lessee and the Lessee relying on the representations and warranties of the Lessor, hereby agrees to take the Asset(s) on lease for its Business and/ or any other purpose that it may in its sole and exclusive discretion deem fit, during the Lease Term by paying the Lease Rent as mutually agreed to by the Parties in writing.</li>

                                <li>3.2	The Lessor shall ensure that is supplies new, well maintained (maintained as per OEM prescribed User manual/Standards) Asset(s) for each day of a year, 24 hours a day and through the Lease Term to the Lessee as more specifically set out in Annexure A.</li>

                                <li>3.3	The Lessor expressly agrees that the Asset(s) that are given on lease to the Lessee shall not be given to any person (whether legal or natural) on lease by the Lessor and neither shall the Lessor be entitled to create any encumbrance on the same or hypothecate the same without express written permission from the Lessee during the Lease Term contemplated in this Agreement.</li>
                            </ul>
                            <p className='pt-3'>4.	TERM </p>

                            <p> Subject to the terms and conditions set out herein, this Agreement shall be valid for a period of [Please insert] (in words) years i.e., it shall commence on the Lease Term which shall begin on the Lease Commencement Date and shall end on the Lease End Date unless terminated earlier by either Party in accordance with the provisions of this Agreement.</p>

                            <p className='pt-3'> 5.	DELIVERY OF ASSET(S)</p>
                            <ul>
                                <li> 5.1	The Lessor warrants that he/she has purchased Asset(s) more particularly described in Annexure A to this Agreement to lease the said Asset(s) to the Lessee. The Lessor shall arrange to give delivery of the Asset(s) to the Lessee from the designated location as mutually determined by the Parties. Any loss or damage to the Asset(s) in transit to and from designated location to Lessee's address shall be treated as an accident case and the liability shall be covered as per the Insurance Policy.</li>
                                <li> 5.2	The Parties agree that the title to the Asset(s) procured from the Lessor will remain with the Lessor during the Lease Term subject to the terms and conditions of this Agreement.</li>
                            </ul>

                            <p className='pt-3'> 6.	PAYMENT OF LEASE RENT</p>
                            <ul>
                                <li>6.1	The Lessee shall pay the Lease Rent for the Asset(s) on or before the 20th day of the succeeding English calendar month for usage of the Asset(s) for the preceding English calendar month until the date of termination or the expiration of the Lease Term whichever is earlier. As an example, the Lessee shall be permitted to make payments for the month of January on or before the 20th day of the month of February, and for the month of February, the Lessee shall be permitted to make payments of the Lease Rent on or before the 20th day of March.</li>

                                <li>6.2	The Lessor shall nominate the bank account to which the Lessee shall make payments of the Lease Rent to the Lessee in writing.</li>


                                <li>6.3	The Parties expressly agree that no payments whatsoever in addition to the Lease Rent shall be paid by the Lessee to the Lessor.</li>


                                <li>6.4	The Lease Rental as set out in Annexure B shall be subject to all applicable taxes including Goods & Services Tax, Compensation Cess and any other statutory taxes levied or assessed at the applicable rates.</li>


                                <li>6.5	In the event, the Lessee fails to make the payment in the time frame as stipulated above, the Lessee shall pay an interest of 1% (one percent only) on the Lease Rent for every 30 (thirty) days of delay.</li>

                                <li>6.6	The Parties expressly agree that the Lease Rent for the Lease Term shall be fixed and shall remain unchanged during the Lease Term. The Lessor shall not be permitted to increase the Lease Rent for any reason whatsoever.</li>
                            </ul>
                            <p className='pt-3'> 7.	OBLIGATIONS OF THE LESSOR</p>
                            <ul>
                                <li>7.1	The Lessor undertakes that he/she shall at his/her own cost obtain the Insurance Policy for the Assets in the name of the Lessor with an Insurer acceptable to the Lessee covering all the losses including but not limited to those arising out of fire, accident, burglary, malicious damage, natural disasters, explosion, machinery breakdowns, illegal activities by third parties, theft and such other risks as may be customary in the ordinary course of business. The Lessor shall furnish a copy of the Insurance Policy, so obtained, to the Lessee.</li>

                                <li>7.2	The Lessor shall ensure that it shall at no time do any act that causes the rights of the Lessee to use the Asset(s) to be jeopardized in any manner whatsoever, and in the event of such happenings, the Lessor agrees that the Lessee may terminate this Agreement immediately.</li>

                                <li>7.3	The Lessor shall be responsible for payment of all applicable taxes including import taxes, if any, governmental taxes, road transport taxes, insurance costs and all other costs not expressly specified to be borne by the Lessee in accordance with the terms of this Agreement.</li>

                                <li>7.4	The Lessor represents and warrants that the Lessor shall at his/her sole and exclusive cost register the Asset(s) in the name of the Lessee with the transport authorities and shall ensure that the existence of this Agreement is entered in the certificate of the registration of the Asset(s), as required under Applicable Law. The Lessor shall ensure that it signs all such documents that would be required to grant the Lessee the rights contemplated under this Agreement.</li>

                                <li>7.5	In case of any breach and/ or failure by the Lessor of any Applicable Law and compliance thereof and/ or any terms of this Agreement, due to which the Lessee’s use of the Asset(s) is affected in any way causing any damage and/ or loss of revenue to the Lessee, then the  Lessor expressly agrees that he/she shall compensate the Lessee to the fullest extent permissible under law for the same.</li>

                                <li>7.6	The Lessor hereby undertakes not to transfer, assign or otherwise dispose of or purport to transfer, assign or otherwise dispose by way of hypothecation, mortgage, charge, sublease, sale, assignment, pledge, hire, encumbrance, license or create a charge in favour of a bank or a financial institution or trustees for debenture holders in favour of the Asset(s) during the Lease Term.</li>

                                <li>7.7	The Lessor undertakes and hereby grants to the Lessee the right to make any additions, alterations, brand and/ or add the Lessee’s signage to the Asset(s) in any way that the Lessee may deem fit.</li>

                                <li>7.8	The Lessor expressly agrees that the Lessee shall have the complete right to use the Asset(s) in any territory within the Republic of India it may in its sole and exclusive discretion deem fit without any prior consent of the Lessor and the Lessor shall in no way restrict such use.</li>

                                <li>7.9	It is hereby clarified that the Lessee shall not be responsible and/ or liable for Superdari Event caused as a result of the Lessor or any of its representatives in relation to the Asset(s). In such situations where the Lessor is responsible for any Superdari Event, the Lessee shall be at liberty to forthwith terminate this agreement in accordance with the provisions of clause 14 (Termination and Asset Return) mentioned hereinbelow.</li>
                            </ul>
                            <p className='pt-3'>8.	OBLIGATIONS OF THE LESSEE</p>

                            <ul><li>8.1	The Lessee undertakes that it shall not use the Asset(s) for any unlawful purpose whatsoever and shall operate the Asset(s) as per Applicable Law.</li>

                                <li>8.2	Subject to Force Majeure events, the Lessee shall make timely payments of the Lease Rent which shall be subject to the Lessee’s right of uninterrupted use of the Asset(s).</li>

                                <li>8.3	The Lessee shall notify the Lessor in writing of any occurrence which gives rise or may give rise to a claim under the Insurance Policy and/ or any claim under any other such policy.</li>

                                <li>8.4	The Lessee shall ensure that the Asset(s) is only used by persons satisfying the requirements laid down under Applicable Law and regulations pertaining to such use.</li>

                                <li>8.5	The Lessee shall not do or omit to do, cause to be done any act or thing by which the warranties given by the manufacturer in relation to the Asset(s) would be invalidated or become unenforceable.</li>

                                <li>8.6	The Lessee shall authorize the Lessor to fill in, alter, amend, sign or complete such forms, documents or papers relating to the regional transport office or the Insurer to give full and complete effect thereof.</li>

                                <li>8.7	In case the Asset(s) is impounded for a Superdari Event for a reason that may be attributed directly to a fault of the Lessee, then only in such situation the Lessee will be liable to get the Asset(s) released from the relevant authorities. The Parties hereby agree that the Lessor shall offer unconditional support and assistance that may be required to assist the Lessee in the release of the Asset(s), and all the costs and expenses incidental thereto will be borne equally by the Parties. If in the opinion the Insurer, the Asset(s) is technically no longer repairable, or the costs of repair are otherwise disproportionate to the current book value, this Agreement shall be terminated with immediate effect.</li>
                            </ul>
                            <p className='pt-3'> 9.	COST AND EXPENSES</p>
                            <ul>
                                <li>9.1	The Lessor shall be fully and unconditionally liable for the maintenance of the Asset(s) till the time the warranty of the Asset(s) subsists. The Lessee shall make the Asset(s) available for regular maintenance to the Authorized Dealer for servicing of the Asset(s) during warranty period.</li>

                                <li>9.2	At the end of the warranty period and for the remainder of the Lease Term, the Lessee will bear all repair, maintenance, operating and running costs of the Asset(s) and such repairs and/ or maintenance shall be conducted at ay designated workshop that the Lessee may in its sole and exclusive discretion deem fit.</li>

                                <li>9.3	Notwithstanding anything contained in this Agreement, all other costs apart from those mentioned in clause 9.1 and 9.2 hereinabove including but not limited to accidental cost, damages cost and any other cost, as the case may be, shall be covered by the Insurance Policy and the Lessee shall not be liable to make any payments whatsoever in relation to the Asset(s). However, notwithstanding anything contained herein, all costs being incidental to ownership of the Asset(s) will be borne solely by the Lessor.</li>
                            </ul>
                            <p className='pt-3'>10.	REPRESENTATIONS AND WARRANTIES OF THE PARTIES</p>
                            <ul>
                                <li> 10.1	Each Party hereby represents, warrants and covenants to the other Party that:</li>

                                <li> 10.1.1	It has the necessary power to enter into and perform its respective obligations under this Agreement;</li>

                                <li>10.1.2	All actions, conditions and things required to be taken, fulfilled and done in order to:</li>

                                <li>i.	enable them to lawfully enter into and perform their obligations under this Agreement; and</li>

                                <li>ii.	ensure that those obligations are legally binding and enforceable have been taken, fulfilled and done.</li>

                                <li>10.1.3	The Lessor and the authorized signatory(ies) of the Lessee are duly authorized to execute this Agreement.</li>

                                <li>10.1.4	The execution and delivery of this Agreement and performance hereunder will not result in a breach of any other agreement entered into by the Lessor and/ or the Lessee and will not conflict with any order, judgment, rule or regulation by which the Parties are bound.</li>

                                <li>10.1.5	The Lessor nor the Lessee is subject to any proceedings or decision which may negatively affect the said parties economic/financial condition or business;</li>

                                <li>10.1.6	All information provided / to be provided pursuant to this Agreement, is true, complete and correct in all respects.</li>
                            </ul>
                            <p className='pt-3'> 11.	INDEMNITY</p>
                            <ul>
                                <li>The Parties (each in such case an “Indemnifying Party”) will indemnify, defend and hold harmless the other Party ("Indemnified Party”), its directors, partners, employees and agents, from and against all losses incurred or suffered by the Indemnified Party that may arise from (i) any theft, third party claims, accident or damage to the Asset(s); or (ii) any breach or non-fulfillment of any of the covenants, undertaking or obligation of either Party under this Agreement.</li>

                                <p> 12.	LIMITATION OF LIABILTY</p>

                                <li>The Parties expressly agree that the total, aggregate and cumulative liability of the Lessee arising under this Agreement shall not exceed 2 (two) months’ Lease Rental as due to the Lessor under this Agreement.</li>
                            </ul>
                            <p> 13.	FORCE MAJEURE</p>
                            <ul>

                                <li>13.1	Neither Party shall be liable for any default or delay in the performance of its obligations if to the extent such default is caused, directly or indirectly, by fires, floods, power failures, Acts of God, act of public enemy, civil commotion, sabotage, wars, insurrections, riots, pandemic, epidemic, lockdowns, travel restrictions, outbreaks or any such event declared as a force majeure event by a governmental/ regulatory/ judicial/ quasi-judicial/ any such body that has the power to declare an event as a force majeure event (“Event of Force Majeure”). In the event that any of the aforesaid events continue for more than 15 (fifteen) days, then notwithstanding anything contained in this Agreement, the Lessee shall have the sole right to terminate this Agreement immediately by giving written notice to the Lessor and shall return the Asset(s) to the Lessor in accordance with the terms of this Agreement.</li>

                                <li>13.2	Notwithstanding anything contained in this Agreement, the payment obligations of the Lessee towards the Lessor shall fall within the protection of this force majeure clause.</li>
                            </ul>
                            <p className='pt-3'> 14.	TERMINATION AND ASSET RETURN</p>
                            <ul>

                                <li>14.1	Either Party may terminate this Agreement without cause by giving a written notice of 90 (ninety) days to the other Party.</li>

                                <li>14.2	This Agreement may be terminated forthwith by either Party by giving a written notice to the other Party, upon the occurrence of any of the following:</li>

                                <li>a.	If either Party breaches any of its obligations under this Agreement, provided such breach is not cured or remedied within 15 (fifteen) days from the date on which a written notice is given by the other Party;</li>

                                <li>b.	If either Party undertakes any act which causes or is likely to cause (as may be mutually decided by the parties) substantial harm to the other Party’s business, goodwill or reputation, upon a written notice, provided such breach is not cured or remedied within 7 (seven) days from the date on which a written notice is given by the other Party; or</li>

                                <li>c.	Irreparable damage / total loss caused to the Asset(s) or theft of the Asset(s).</li>

                                <li>d.	Either Party is bankrupt or makes an assignment for the benefits of creditors, or consents to the appointment of a trustee or receiver is appointed in relation to a substantial part of such Party’s property; or bankruptcy, reorganization or insolvency proceedings, voluntary or otherwise, affecting such Party, shall be instituted by or against the Party.</li>

                                <li>e.	Either Party suffers an adverse material change in the financial condition from the date hereof, and as a result thereof.</li>

                                <li>14.3	The Lessee shall make all payments of the Lease Rental due and payable to the Lessor till the date of the termination of this Agreement.</li>

                                <li>14.4	Upon termination and/ or expiration of this Agreement, the Lessee may in its sole and discretion have the first right to purchase the Asset(s) from the Lessor. The Lessee shall buy these Asset(s) based on the written down value of the Asset(s) at the end of the Lease Term that shall be calculated based on straight line depreciation which shall be assessed by the Parties mutually. The cost of such transfer of the Asset(s) shall be borne by the Parties equally. The Lessor shall do all such things as may be required including signing all such documents to ensure that the Asset(s) ownership is passed to the Lessee if the Lessee shall choose to purchase the same from the Lessor.</li>

                                <li>14.5	In the event, the Lessee does not choose to buy the Asset(s) at the end of the Lease Term, the Lessee shall deliver the Asset(s) (normal wear and tear accepted) with all documents it received at the time of the Lease Commencement Date, back to the Lessor at the local address of the Lessor (which for the sake of clarity shall mean an address within the geographical territory of the Republic of India).</li>
                            </ul>
                            <p className='pt-3'> 15.	INTELLECTUAL PROPERTY RIGHTS</p>
                            <ul>

                                <li>15.1	The Parties acknowledges that all intellectual property developed by in relation to this Agreement shall belong to the Lessee absolutely and the Lessee alone, at its sole discretion, shall have the right to exploit any and all of the said rights by any means throughout the world during and after the termination of this Agreement. The Lessor or anyone claiming on its behalf (whether legal or natural) shall not have nor claim any right in any of the aforementioned intellectual property in any manner whatsoever.</li>

                                <li>15.2	The Lessee shall remain the sole and exclusive owner of all the rights, title and interest, in patents, copyrights, designs, trademarks, service marks (whether registered or not)  and all other forms of intellectual property rights in trade secret, know how, ideas, drawings, materials, documents or any other technical information provided or disclosed or shared with the Lessor under this Agreement ("Magenta Background IP"), and that no rights or license in relation to any of Magenta background IP is deemed to have been granted to the Lessor. Any ideas, know-how and inventions made by the Lessee’s employees as a result of the any work carried out under this Agreement shall also be considered as Magenta Background IP.</li>

                                <li>15.3	The Lessor agrees, acknowledges and undertakes that it shall:</li>

                                <li>a.	Preserve and maintain the distinctiveness of Magenta Background IP and shall not contest or challenge validity of Magenta Background IP or do any act at any time that may in any way prejudice the same.</li>

                                <li>b.	Not reproduce and/or use or Magenta Background IP for any purpose and acknowledge that the same is proprietary and confidential to Magenta;</li>

                                <li>c.	Neither directly or indirectly, disclose Magentas Background IP to any person other than those, who need to know the same for the purpose of executing this Agreement, provided however that, the Lessor shall ensure that such person is restrained from disclosing or transmitting Magenta Background IP, as the case may be to any third party.</li>

                                <li>d.	Forthwith discontinue use of Magenta Background IP (as the case may be) and return and/or destroy as directed by Magenta (as the case may be) all designs, drawings, technical information furnished by Magenta (as the case may be) upon termination or expiration of this Agreement.</li>

                                <li>15.4	Notwithstanding anything contained in this Agreement, in addition to any rights set out herein, the Lessee shall be at liberty to approach a competent court for procuring an injunction and/ or seeking of damages to protect Magenta’s Background IP. It is hereby clarified that the Lessee shall have the complete right to take such action against the Lessor at the Lessor’s sole risk as to cost and consequences.</li>
                            </ul>
                            <p className='pt-3'> 16.	CONFIDENTIALTY</p>
                            <ul>
                                <li>The Parties undertake that they shall at all times keep confidential (and shall use best endeavours to procure that their respective employees and agents keep confidential) any confidential information of other Party which is in their possession or which they may acquire and shall not disclose such information except with the consent of the other Party which disclosed the confidential information. For the purposes of this clause, confidential information shall mean and include any valuable, non-public, competitively sensitive data and information relating to the business or operations of the Party making the disclosure, including accounting information, pricing policy, affiliate relationship information, business plans, client lists, and methods of operation and any other information deemed to be confidential information by the disclosing Party (“Confidential Information”). In addition to the aforesaid, the Lessor shall keep the contents of this Agreement and any correspondence between the Parties as strictly confidential and shall treat the same as confidential information whether mentioned as such by the Lessee or not.</li>
                            </ul>
                            <p>17.	GOVERNING LAW AND DISPUTE RESOLUTION</p>
                            <ul>
                                <li>17.1	Any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination, interpretation or application of the present Agreement, the Parties shall first endeavour an amicable settlement. The Party raising the dispute shall be required to send written notice of the same to the other Party. If after such notice for amicable settlement, the Parties fail to reach such settlement within thirty (30) days from the date of first written notice regarding the dispute raised, the Parties shall refer the dispute to Arbitration in accordance with the Arbitration and Conciliation Act, 1996. The place and seat of arbitration shall be Bangalore. The arbitral tribunal shall consist of 1 (one) arbitrator jointly appointed by both the Parties, and the language of the arbitration shall be English. The decision or award of the arbitral tribunal shall be final and binding upon the Parties and shall be set forth in a reasoned opinion. [JP Note: Please confirm.]</li>

                                <li>17.2	This Agreement shall be interpreted and governed by the laws of India. Subject to clause 17.1, the courts of judicature at Hyderabad alone and no other courts shall be entitled to entertain and try any dispute or matter relating to or arising out of this Agreement. [JP Note: Please confirm.]</li>
                            </ul>
                            <p className='pt-3'> 18.	MISCELLANEOUS</p>
                            <ul>
                                <li>18.1	Notices</li>

                                <li>All notices under this Agreement shall be written in English and shall be deemed to be validly served: (a) in case of delivery by hand, when hand delivered to the other Party; or (b) when delivered by courier or by any postal service that provides a receipt of delivery, on the date contained in the delivery receipt that the sending Party receives from the delivery service provider; or (c) for electronic mail notification with return receipt requested, upon the obtaining of a valid return receipt from the recipient.</li>

                                <li>18.2	Entire Agreement</li>

                                <li>This Agreement constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes prior negotiations, representations, or agreements, either written or oral.</li>

                                <li>18.3	Severance of terms</li>

                                <li>In the event that any of the provisions of this Agreement shall be found to be void but would be valid if some part thereof was deleted or the scope, period or area of application were reduced, then such provision shall apply with the deletion of such words or such reduction of scope, period or area of application as may be required to make such provisions valid and effective.</li>

                                <li>18.4	Waiver</li>

                                <li>No failure or delay on the part of any of the Parties to this Agreement relating to the exercise of any right, power, privilege or remedy provided under this Agreement shall operate as a waiver of such right, power, privilege or remedy or as a waiver of any preceding or succeeding breach by the other Party to this Agreement nor shall any single or partial exercise of any right, power, privilege or remedy preclude any other or further exercise of such or any other right, power, privilege or remedy provided in this Agreement all of which are several and cumulative and are not exclusive of each other or of any other rights or remedies otherwise available to a Party at law or in equity.</li>

                                <li>18.5	No Partnership</li>

                                <li>This Agreement does not constitute and shall not be construed as constituting a partnership or joint venture between the Parties hereto. Neither Party shall have any right to obligate or bind the other Party or Parties other than stipulated in this Agreement if any manner whatsoever, and nothing contained in this Agreement shall give, any rights of any kind to any third parties.</li>

                                <li>18.6	Assignment</li>

                                <li>Neither Party shall be entitled to assign all or any of its rights under this Agreement to any party without prior permission from the other  Party.</li>


                                <li> 18.7	Amendments</li>

                                <li>No change or modification of this Agreement shall be valid unless the same shall be in writing and signed by both Parties.</li>

                                <li>18.8	Counterparts</li>

                                <li>This Agreement may be signed in any number of counterparts, each of which is an original and all of which, taken together, constitutes one and the same instrument.</li>

                                <li>18.9	Expenses</li>
                                <li>Each Party shall bear its own costs and expenses in relation to the execution of this Agreement. The stamp Duty in relation to this Agreement shall be borne by the Parties in equal proportion.</li>


                                <li>IN WITNESS WHEREOF, the Parties hereto have caused this Agreement to be duly executed and delivered by their duly authorized representatives on the day and year first above written.</li>
                            </ul>

                            <p> SIGNED AND DELIVERED On behalf of the Lessor</p>
                            <p>[other Party]</p>



                            <p> By:</p>


                            <p> SIGNED AND DELIVERED On behalf of the Lessee:</p>
                            <p>MAGENTA EV SOLUTIONS PRIVATE LIMITED</p>



                            <p> Name:</p>

                            <p> Title:</p>

                            <p> ANNEXURE B</p>

                            <p> LEASE RENT</p>


                        </div>
                        <div className="pt-5">
                            <input type="checkbox"
                                // checked={checked}
                                onChange={(e) => setChecked(e.target.checked)} /><span> Agree with terms and condition</span>

                        </div>
                        <div className='pt-3'>
                            <button
                                type="submit"
                                className="btn btn-style"
                                disabled ={!checked}
                                onClick={() => { navigate("../pay", { replace: true }) }}
                            >
                                Continue
                            </button>
                        </div>

                    </div>
                </section>
            </div></>
    )
}

export default Agreement