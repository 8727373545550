import React, { useEffect } from "react";
import Typewriter from 'typewriter-effect';
import { NavLink } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import "./testimonials.css";

const Hero = () => {

  const styleObj = {
    color: "#000000",
    fontWeight: '900'
  }

  const divoverFlow ={
    overflowX:'hidden',
    overflowY:'hidden',
  }

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <>

      {/* <header>
        <section className="container main-hero-container  ">
          <div className="row ">
            <div className="col-12 col-lg-12 align-middle">
              <h1 className="main-hero-title">
              Accelerate electric mobility with<br />

                <Typewriter
                  options={{
                    strings: ['faster delivery speeds and ', 'smarter logistics solutions'],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>


            </div>
            <div className="col-12 col-lg-12 align-middle text-center">
              <img class="fixed-ratio-resize" src="images/ani.gif" alt="logo" />
            </div>
          </div>
        </section>
      </header> */}

      <div className="header2 about-bg">
        <section className="container techdesk-container pt-5">
          <div className="row pt-5">
            <div data-aos="fade-right" className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start" style={{ overflowX:'hidden',overflowY:'hidden'}} >
              {/* <div 
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start "></div> */}

              {/* <p className="subtitle">
                WE’RE HERE
                  </p> */}
              <h1 className="techdesk-title">
            
              <div class="element"></div>

 Delivered in <br/>Electric Vehicle
                {/* <Typewriter
                    options={{
                      strings: ['transportation needs', 'Intelligent transportation needs'],
                      autoStart: true,
                      loop: true,
                      delay: 18,
                      deleteSpeed:18,
                    
                    }}
                  />  */}

              </h1>
              <p className="techdeskabout-para pt-5">
              We help you in making your transportation and delivery needs  <b style={styleObj}>CLEAN</b> and <b style={styleObj}>EFFICIENT </b>using smart electric vehicles.</p>
              <p className="techdeskabout-para pb-5">Leave us your contact coordinates and we will reach out to you to understand your transportation requirements better.

              </p>

              {/* <form class="form-inline my-2 my-lg-0">
                <a href="/about" class="header-btn my-2 my-sm-0 ">Know More</a>
              </form> */}
              <p className="techdeskabout-para">
              To book a delivery, call us
              </p>
              <form class="form-inline my-2 my-lg-0">

                <button onClick={() => window.open("tel:1800-123-0267")} class="header-btn1 my-2 my-sm-0 "><i class="fa-solid fa-phone"></i> 1800 123 0267</button>

                <br />
              </form>




            </div>
            {/*  --------------- main header right side--------------  */}
            <div data-aos="fade-left" className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images" style={{ overflowX:'hidden',overflowY:'hidden'}}>
              {/* <div
                  data-aos="fade-left"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images"></div> */}
              <img
                src="./images/hero1.png"
                alt="heroimg"
                className="img-fluid"
              />
            </div>
          </div>

        </section>

      </div>
    </>
  );
};

export default Hero;
