import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


    const Techdesk = () => {
      useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      return (
        <>
          <div className="header1">
            <section className="container techdesk-container probootstrap-section">
              <div className="row ">
                <div
                data-aos="fade-right"
                 className="col-12 col-lg-6 p-5 header-left-side d-flex justify-content-center flex-column align-items-start ">
                {/* <p className="subtitle">
                Intelligent Delivery with
                  </p> */}
                  <h2 className="techdesk-title">
                  Intelligent Delivery with Zero Carbon Emissions
                  </h2>
                  <div className="linePreloader"></div>
                  <p className="techdeskabout-para pt-5">
                  Our proprietary cloud based technology helps our fleet of electric vehicles to:
                  </p>
                  <ul className="list-group list-para">
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>   Handle today’s real world supply chain and logistics operations.
                
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i> To identify dynamic on the ground constraints such as traffic and route restrictions.  
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i>  To take agile and instant decisions to reduce manual intervention, waiting times. 
                </li>
                <li className="list-group-item d-flex">
                <i class="fa-solid fa-circle-check p-2"></i> To enable faster and efficient delivery services.  
                </li>
              </ul>
                  {/* <form class="d-flex">
                   <button class="btn btn-style" type="submit">Read More</button>
                   </form> */}

                </div>
                {/*  --------------- main header right side--------------  */}
                <div
                data-aos="fade-left"
                 className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
                  <img
                    src="./images/technology.png"
                    alt="heroimg"
                    className="img-fluid"
                  />
                  {/* <img
                    src="./images/hero4.jpg"
                    alt="heroimg4"
                    className="img-fluid main-hero-img2"
                  /> */}
                </div>
              </div>
            </section>
          </div>
        </>
      );
    };


export default Techdesk