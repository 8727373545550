import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

const News = () => {
    
    return (
        <>
            <div className='header3  pb-5'>
                <div className="container news ">
                    <div className="row news-title">
                        <div className="col-2 col-lg-2 header-left-side d-flex justify-content-center flex-column  align-items-center">

                            <h2 className="techdesk-title">
                                Flash News
                            </h2>

                        </div>
                        <div className='col-10 col-md-10 '>


                            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner align-self-center">
                                    <div class="carousel-item active ">
                                        <div class="carousel-caption ">
                                            <div class="row ">
                                                <div class="col-12 col-md-2 align-self-center d-none d-sm-block ">
                                                    <img src="./images/news/mint.png"   className="img-fluid"/>
                                                </div>
                                                <div className="col-12 col-md-10 news-heading d-flex flex-column justify-content-center">
                                                    <p ><a href='https://www.livemint.com/auto-news/magenta-to-use-100-electric-cargo-fleet-for-last-mile-delivery-read-more-11638606103894.html' target="_blank">Magenta to use 100 electric cargo fleet for last mile delivery</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="carousel-caption">
                                            <div class="row">
                                                <div class="col-12 col-md-2 align-self-center d-none d-sm-block">
                                                    <img src="./images/news/eco.png"   className="img-fluid"/>
                                                </div>
                                                <div className="col-12 col-md-10 news-heading d-flex flex-column justify-content-center">
                                                    <p ><a href='https://economictimes.indiatimes.com/industry/renewables/magenta-to-deploy-mms-treo-zor-electric-cargo-vehicles-in-bengaluru/articleshow/84231063.cms?from=mdr' target="_blank">Magenta to deploy M&M's Treo Zor electric cargo vehicles in Bengaluru</a> </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="carousel-caption ">
                                            <div class="row">
                                                <div class="col-12 col-md-2 align-self-center d-none d-sm-block">
                                                    <img src="./images/news/auto.png"   className="img-fluid"/>
                                                </div>
                                                <div className="col-12 col-md-10 news-heading d-flex flex-column justify-content-center">
                                                    <p ><a href='https://www.autocarpro.in/news-national/mahindra-partners-magenta-for-endtoend-ev-solutions-in-bangalore-for-treo-zor-79554' target="_blank">Mahindra partners Magenta for end-to-end EV solutions in Bangalore for Treo Zor</a> </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="carousel-caption ">
                                            <div class="row">
                                                <div class="col-12 col-md-2 align-self-center d-none d-sm-block">
                                                    <img src="./images/news/saurenergy.png"   className="img-fluid"/>
                                                </div>
                                                <div className="col-12 col-md-10 news-heading d-flex flex-column justify-content-center">
                                                    <p ><a href='https://www.saurenergy.com/ev-storage/magenta-expands-evet-offering-to-mumbai-and-chennai' target="_blank">Magenta expands EVET offering to Mumbai and Chennai</a> </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="carousel-caption ">
                                            <div class="row">
                                                <div class="col-12 col-md-2 align-self-center d-none d-sm-block">
                                                    <img src="./images/news/et-auto.png"   className="img-fluid"/>
                                                </div>
                                                <div className="col-12 col-md-10 news-heading d-flex flex-column justify-content-center">
                                                    <p ><a href='https://auto.economictimes.indiatimes.com/news/aftermarket/evet-forges-into-the-electric-fleet-for-food-delivery-partners-with-leading-companies-in-the-food-industry/91317587' target="_blank">Food co.s to use EVET e-fleet for mid-mile delivery in Bengaluru</a> </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>





                        </div>

                    </div>

                </div>
            </div>


        </>
    )
}

export default News