import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Servicescard = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className='header1'>
        <section class="services" id="services">
          <div class="container techdesk-container">
            <div class="row">
              <div className="col-12 col-lg-12 pb-5 header-left-side d-flex justify-content-center flex-column  align-items-center">
                {/* <p className="subtitle">
            Goods Deliveries
        </p> */}
                <h2 className="techdesk-title">
                  Goods Deliveries! Electrified
                </h2>
                <div className="linePreloader1"></div>
              </div>

              <div
                data-aos="fade-right"

                class="col-md-6 col-lg-3">
                <div class="box">
                  <div class="icon">
                    <img
                      src="./images/manifacturer.png"
                      alt="heroimg"
                      className="img-fluid"
                    />
                  </div>
                  <h4 class="title">Electric </h4>
                  <h4 class="title1"> First-Mile</h4>

                  <p class="description text-center">From farm or factory, we pick up and deliver products to warehouses with the best electric fleet combinations.</p>
                </div>
              </div>

              <div
                data-aos="fade-up"

                class="col-md-6 col-lg-3">
                <div class="box">
                  <div class="icon">
                    <img
                      src="./images/warehouse.png"
                      alt="heroimg"
                      className="img-fluid"
                    />
                  </div>
                  <h4 class="title">Zero Carbon </h4>
                  <h4 class="title1"> Mid-Mile</h4>

                  <p class="description text-center">Delivering goods from warehouses to distribution center or fulfilment facilities, to maximize business volumes.</p>

                </div>
              </div>

              <div
                data-aos="fade-up"

                class="col-md-6 col-lg-3">
                <div class="box">
                  <div class="icon" >
                    <img
                      src="./images/customer.png"
                      alt="heroimg"
                      className="img-fluid"
                    />
                  </div>
                  <h4 class="title">Clean</h4>
                  <h4 class="title1">Last-Mile</h4>

                  <p class="description text-center">Delivering goods or products to end consumers, on time, with a delightful customer experience and with a smile.</p>
                </div>
              </div>

              <div
                data-aos="fade-left"

                class="col-md-6 col-lg-3">
                <div class="box">
                  <div class="icon" >
                    <img
                      src="./images/return.png"
                      alt="heroimg"
                      className="img-fluid"
                    />
                  </div>

                  <h4 class="title">Reverse</h4>
                  <h4 class="title1">Logistics</h4>

                  <p class="description text-center">Highly secure and seamless reverse logistics operations with increased transparency, granular visibility and using electric fleets.</p>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Servicescard