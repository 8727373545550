import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./testimoni.css"

const Testimonials = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const styleObj = {
        color: "#000000",
        fontWeight: '900'
    }

    return (
        <>
            <div className='header1' >
                <section className="container techdesk-container probootstrap-section">
                    <div className="row">
                        <div className="col-12 col-lg-12 header-left-side d-flex justify-content-center flex-column align-items-center ">
                            <h2 className="techdesk-title">
                            Testimonial 
                            </h2>
                        </div>

                        <div className="testimonials-evet">
                            <div className="row people">
                                <div className="col-md-6 col-lg-6 item">
                                    <div className="box">
                                        <p className="description ">We at SPRINK
                                            are committed towards altemative and sustainable
                                            mobility options for our delivery requirements.
                                            By partnering with EVET in December last year,
                                            we were able to transition 70% of our vehicles to
                                            electric, and we are on track to convert 100%
                                            in the next 3 monts.
                                            Working with EVET has helped us
                                            tremendously in our journey towards
                                            achieving this feat..</p>
                                    </div>
                                    <div className="author"><img className="rounded-circle" src="./images/abhishek.png" />
                                        <h5 className="name">Mr. Abhishek Mandal </h5>
                                        <p className="title">Co-Founder and COO, SPRINK</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 item">
                                    <div className="box">
                                        <p className="description pb-4">As part of Elior sustainability initiative and achieving a breakthrough result in reducing carbon emissions our company has taken a initiative for increasing EVET vehicle usage in staggered manner to delivering the food to client location and reduced 1000 kgs of carbon emissions during 1st quarter of fy 2022</p>
                                    </div>
                                    <div className="author"><img className="rounded-circle" src="./images/david.png" />
                              
                                        <h5 className="name">David Edward Raj</h5>
                                        <p className="title">Director - Operations and Innovation, ELIOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};


export default Testimonials