import React, { useState } from "react";
import './calculator.css';
import { useNavigate } from "react-router-dom";


const Calculator = () => {
    let navigate = useNavigate();


    const [vehicles, setVehicles] = useState(0);
    // const [years, setYears] = useState(3);
    const [interest, setInterest] = useState(0);
    const [finterest, setFinterest] = useState(0);
    const [monthly, setMonthly] = useState(0);
    const [result, setResult] = useState(0);
    const [fixedd, setFixedd] = useState(0);
    const [cbondinterest, setCbondinterest] = useState(0);
    const [cbond, setCbond] = useState(0);
    const [niftyinterest, setNiftyinterest] = useState(0);
    const [nifty, setNifty] = useState(0);

    const investedamount = vehicles * 300000;
    const newarray = { investedamount, interest, monthly, result }
    console.log("new array", newarray)
    const rateofinterest = () => {
        // Magenta EVET return
        localStorage.setItem('UserData', JSON.stringify(newarray));


    };

    return (
        <>
            <div className="header1 ">
                <div className="container calbox about-bg">
                    <form>
                        <div className="row">
                            <div className=" col-12 col-lg-12 header-left-side d-flex justify-content-center flex-column align-items-center techdesk-container">
                                <h2 className="techdesk-title">
                                    Magenta Mobility's Asset Leasing Programme - Features and Benefits
                                </h2>
                                <p className="techdeskabout-para pt-5">
                                    Under the Magenta Mobility's Asset Leasing programme, invest and be part of the booming EV ecosystem and generate risk-free high returns on your investments
                                </p>

                            </div>
                            {/* <div className="col-12 col-lg-6">
                                <img
                                    src="./images/calculator.png"
                                    alt="heroimg"
                                    className="img-fluid"
                                />
                            </div> */}
                            <div className="col-12 col-lg-3 ">
                                <div className="col-12 col-lg-12 contact-input-feild1 contact-rightside">
                                    <h2>Number of vehicles <strong>{vehicles}</strong></h2>
                                    <h2>0 <input type="range" step="1" min="0" max="20" value={vehicles}
                                        onChange={(e) => {
                                            setVehicles(e.target.value);
                                            // setVehicles(e.target.value * 300000); 
                                            // rateofinterest(e);    

                                            e.preventDefault();

                                            // Calculation Returns
                                            const amount = e.target.value * 300000;
                                            const interest = 10;
                                            const years = 4;

                                            const userAmount = Number(amount);
                                            const calculatedInterest = Number(interest) / 100 / 12;
                                            const calculatedPayments = Number(years) * 12;
                                            const x = Math.pow(1 + calculatedInterest, calculatedPayments);
                                            const monthlypay = (userAmount * x * calculatedInterest) / (x - 1);
                                            const monthlyPaymentCalculated = monthlypay.toFixed(2);
                                            const monthlypayresult = monthlyPaymentCalculated;
                                            setMonthly(Math.round(monthlypayresult));
                                            const totalpayment = monthlypayresult * years * 12;
                                            setResult(Math.round(totalpayment));
                                            const returnss = totalpayment - monthlypayresult;
                                            setInterest(Math.round(returnss));


                                            //fixed deposite return
                                            const userAmount1 = Number(amount);
                                            const calculatedInterest1 = Number(5.4) / 100 / 12;
                                            const calculatedPayments1 = Number(years) * 12;
                                            const y = Math.pow(1 + calculatedInterest1, calculatedPayments1);
                                            const monthlypay1 = (userAmount1 * y * calculatedInterest1) / (y - 1);
                                            const monthlyPaymentCalculated1 = monthlypay1.toFixed(2);
                                            const monthlypayresult1 = monthlyPaymentCalculated1;
                                            const totalpayment1 = monthlypayresult1 * years * 12;
                                            setFixedd(Math.round(totalpayment1));



                                            //Corporate Bond return
                                            const userAmount2 = Number(amount);
                                            const calculatedInterest2 = Number(9.2) / 100 / 12;
                                            const calculatedPayments2 = Number(years) * 12;
                                            const z = Math.pow(1 + calculatedInterest2, calculatedPayments2);
                                            const monthlypay2 = (userAmount2 * y * calculatedInterest2) / (z - 1);
                                            const monthlyPaymentCalculated2 = monthlypay2.toFixed(2);
                                            const monthlypayresult2 = monthlyPaymentCalculated2;
                                            const totalpayment2 = monthlypayresult2 * years * 12;
                                            setCbond(Math.round(totalpayment2));

                                            //Nifty 50 return
                                            const userAmount3 = Number(amount);
                                            const calculatedInterest3 = Number(13) / 100 / 12;
                                            const calculatedPayments3 = Number(years) * 12;
                                            const znifty = Math.pow(1 + calculatedInterest3, calculatedPayments3);
                                            const monthlypay3 = (userAmount3 * znifty * calculatedInterest3) / (znifty - 1);
                                            const monthlyPaymentCalculated3 = monthlypay3.toFixed(2);
                                            const monthlypayresult3 = monthlyPaymentCalculated3;
                                            const totalpayment3 = monthlypayresult3 * years * 12;
                                            setNifty(Math.round(totalpayment3));

                                            // setNiftyinterest(niftyinterest);
                                            // const nifty = e.target.value * 300000 + niftyinterest;
                                            // setNifty(nifty);

                                        }
                                        }
                                        id='ex1Slider'
                                    /> 20 </h2>
                                    {/* <input
                                        type="number"
                                        class="form-control"
                                        placeholder="1"
                                        onChange={(e) => setVehicles(e.target.value * 300000)}

                                    /> */}
                                </div>
                                <div className="col-12 col-lg-12 contact-input-feild1 contact-rightside">
                                    <h2>Rate of Intrest</h2>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 %"
                                        // onChange={(e) => setInterest(e.target.value)}
                                        disabled
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-12 contact-input-feild1 contact-rightside">
                                        <h2>Duration</h2>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="4 years"
                                            // onChange={(e) => setYears(e.target.value)}
                                            disabled
                                        />
                                    </div>

                                </div>
                                <div className="row">

                                    <h2></h2>
                                    <button onClick={() => {  rateofinterest(); navigate("../authentication", { replace: true })}} class="header-btn1 my-2 my-sm-0 ">Start Investing</button>

                                </div>

                            </div>

                            <div className="col-12 col-lg-2">
                                <div className="">

                                    <div className="techdesk-title2">
                                        <h2>
                                            Total Investment
                                        </h2>
                                        <div className="techdesk-result"><strong>₹ {vehicles * 300000}</strong></div>
                                    </div>
                                    <div className="techdesk-title2">
                                        <h2>
                                            Monthly repayment
                                        </h2>
                                        <div className="techdesk-result"><strong>₹ {monthly}</strong></div>
                                    </div>
                                    <div className="techdesk-title2">
                                        <h2 >
                                            Total Interst
                                        </h2>
                                        <div className="techdesk-result"><strong>₹ {interest}</strong></div>
                                    </div>
                                    <div className="techdesk-title2">
                                        <h2 >
                                            Total return
                                        </h2>
                                        <div className="techdesk-result"><strong>₹ {result}</strong></div>
                                    </div>


                                </div>

                            </div>
                            <div className="col-12 col-lg-7">
                                <div class="container table-responsive rounded">
                                    <table class="table table-bordered  table-hover">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"><h2>IRR</h2></th>
                                                <th scope="col"><h2>Repayment Cycle</h2></th>
                                                <th scope="col"><h2>Tenure</h2></th>
                                                <th scope="col"><h2>Total return</h2></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row"><span style={{ color: "#066ac9" }}>Magenta EVET</span> </th>
                                                <td><span style={{ color: "#066ac9" }}>10%</span>	</td>
                                                <td><span style={{ color: "#066ac9" }}>Bi-Annualy</span>	</td>
                                                <td><span style={{ color: "#066ac9" }}>4</span></td>
                                                <td><span style={{ color: "#066ac9" }}>{result}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Fixed Deposit</th>
                                                <td>5.4%</td>
                                                <td>Yearly</td>
                                                <td>4</td>
                                                <td>{fixedd}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">A+ Grade Corporate Bond	</th>
                                                <td>9.2%</td>
                                                <td>Bi-Annualy</td>
                                                <td>4</td>
                                                <td>{cbond}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Nifty 50 Index(Last 3 Years)</th>
                                                <td>13%</td>
                                                <td>End of Life</td>
                                                <td>4</td>
                                                <td>{nifty}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </>
    )
}

export default Calculator